import * as React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import P5Component from "../components/epoch-p5-component"

const WrapperDiv = styled.div`
  [data-testid] {
    display: flex;
    justify-content: center;
  }
`;

const palettes = [
  {
    name: "palette0",
    colors: [
      "#8458ee",
      "#64c694",
      "#ffffff",
      "#282828"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette1",
    colors: [
      "#8458ee",
      "#64c694",
      "#282828",
      "#ffffff"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette2",
    colors: [
      "#8458ee",
      "#ffffff",
      "#64c694",
      "#282828"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette3",
    colors: [
      "#8458ee",
      "#ffffff",
      "#282828",
      "#64c694"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette4",
    colors: [
      "#8458ee",
      "#282828",
      "#64c694",
      "#ffffff"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette5",
    colors: [
      "#8458ee",
      "#282828",
      "#ffffff",
      "#64c694"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette6",
    colors: [
      "#64c694",
      "#8458ee",
      "#ffffff",
      "#282828"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette7",
    colors: [
      "#64c694",
      "#8458ee",
      "#282828",
      "#ffffff"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette8",
    colors: [
      "#64c694",
      "#ffffff",
      "#8458ee",
      "#282828"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette9",
    colors: [
      "#64c694",
      "#ffffff",
      "#282828",
      "#8458ee"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette10",
    colors: [
      "#64c694",
      "#282828",
      "#8458ee",
      "#ffffff"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette11",
    colors: [
      "#64c694",
      "#282828",
      "#ffffff",
      "#8458ee"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette12",
    colors: [
      "#ffffff",
      "#8458ee",
      "#64c694",
      "#282828"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette13",
    colors: [
      "#ffffff",
      "#8458ee",
      "#282828",
      "#64c694"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette14",
    colors: [
      "#ffffff",
      "#64c694",
      "#8458ee",
      "#282828"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette15",
    colors: [
      "#ffffff",
      "#64c694",
      "#282828",
      "#8458ee"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette16",
    colors: [
      "#ffffff",
      "#282828",
      "#8458ee",
      "#64c694"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette17",
    colors: [
      "#ffffff",
      "#282828",
      "#64c694",
      "#8458ee"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette18",
    colors: [
      "#282828",
      "#8458ee",
      "#64c694",
      "#ffffff"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette19",
    colors: [
      "#282828",
      "#8458ee",
      "#ffffff",
      "#64c694"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette20",
    colors: [
      "#282828",
      "#64c694",
      "#8458ee",
      "#ffffff"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette21",
    colors: [
      "#282828",
      "#64c694",
      "#ffffff",
      "#8458ee"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette22",
    colors: [
      "#282828",
      "#ffffff",
      "#8458ee",
      "#64c694"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette23",
    colors: [
      "#282828",
      "#ffffff",
      "#64c694",
      "#8458ee"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette24",
    colors: [
      "#4ea2be",
      "#7cc6da",
      "#b9e8f2",
      "#ffffff"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette25",
    colors: [
      "#4ea2be",
      "#7cc6da",
      "#ffffff",
      "#b9e8f2"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette26",
    colors: [
      "#4ea2be",
      "#b9e8f2",
      "#7cc6da",
      "#ffffff"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette27",
    colors: [
      "#4ea2be",
      "#b9e8f2",
      "#ffffff",
      "#7cc6da"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette28",
    colors: [
      "#4ea2be",
      "#ffffff",
      "#7cc6da",
      "#b9e8f2"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette29",
    colors: [
      "#4ea2be",
      "#ffffff",
      "#b9e8f2",
      "#7cc6da"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette30",
    colors: [
      "#7cc6da",
      "#4ea2be",
      "#b9e8f2",
      "#ffffff"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette31",
    colors: [
      "#7cc6da",
      "#4ea2be",
      "#ffffff",
      "#b9e8f2"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette32",
    colors: [
      "#7cc6da",
      "#b9e8f2",
      "#4ea2be",
      "#ffffff"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette33",
    colors: [
      "#7cc6da",
      "#b9e8f2",
      "#ffffff",
      "#4ea2be"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette34",
    colors: [
      "#7cc6da",
      "#ffffff",
      "#4ea2be",
      "#b9e8f2"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette35",
    colors: [
      "#7cc6da",
      "#ffffff",
      "#b9e8f2",
      "#4ea2be"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette36",
    colors: [
      "#b9e8f2",
      "#4ea2be",
      "#7cc6da",
      "#ffffff"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette37",
    colors: [
      "#b9e8f2",
      "#4ea2be",
      "#ffffff",
      "#7cc6da"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette38",
    colors: [
      "#b9e8f2",
      "#7cc6da",
      "#4ea2be",
      "#ffffff"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette39",
    colors: [
      "#b9e8f2",
      "#7cc6da",
      "#ffffff",
      "#4ea2be"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette40",
    colors: [
      "#b9e8f2",
      "#ffffff",
      "#4ea2be",
      "#7cc6da"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette41",
    colors: [
      "#b9e8f2",
      "#ffffff",
      "#7cc6da",
      "#4ea2be"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette42",
    colors: [
      "#ffffff",
      "#4ea2be",
      "#7cc6da",
      "#b9e8f2"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette43",
    colors: [
      "#ffffff",
      "#4ea2be",
      "#b9e8f2",
      "#7cc6da"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette44",
    colors: [
      "#ffffff",
      "#7cc6da",
      "#4ea2be",
      "#b9e8f2"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette45",
    colors: [
      "#ffffff",
      "#7cc6da",
      "#b9e8f2",
      "#4ea2be"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette46",
    colors: [
      "#ffffff",
      "#b9e8f2",
      "#4ea2be",
      "#7cc6da"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette47",
    colors: [
      "#ffffff",
      "#b9e8f2",
      "#7cc6da",
      "#4ea2be"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette48",
    colors: [
      "#1a1b1f",
      "#666af1",
      "#eeeef6"
    ],
    size: 3,
    background: "#ffffff"
  },
  {
    name: "palette49",
    colors: [
      "#1a1b1f",
      "#eeeef6",
      "#666af1"
    ],
    size: 3,
    background: "#ffffff"
  },
  {
    name: "palette50",
    colors: [
      "#666af1",
      "#1a1b1f",
      "#eeeef6"
    ],
    size: 3,
    background: "#ffffff"
  },
  {
    name: "palette51",
    colors: [
      "#666af1",
      "#eeeef6",
      "#1a1b1f"
    ],
    size: 3,
    background: "#ffffff"
  },
  {
    name: "palette52",
    colors: [
      "#eeeef6",
      "#1a1b1f",
      "#666af1"
    ],
    size: 3,
    background: "#ffffff"
  },
  {
    name: "palette53",
    colors: [
      "#eeeef6",
      "#666af1",
      "#1a1b1f"
    ],
    size: 3,
    background: "#ffffff"
  },
  {
    name: "palette54",
    colors: [
      "#ffffff",
      "#5dbfdd",
      "#c8dff8",
      "#3a788c"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette55",
    colors: [
      "#ffffff",
      "#5dbfdd",
      "#3a788c",
      "#c8dff8"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette56",
    colors: [
      "#ffffff",
      "#c8dff8",
      "#5dbfdd",
      "#3a788c"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette57",
    colors: [
      "#ffffff",
      "#c8dff8",
      "#3a788c",
      "#5dbfdd"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette58",
    colors: [
      "#ffffff",
      "#3a788c",
      "#5dbfdd",
      "#c8dff8"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette59",
    colors: [
      "#ffffff",
      "#3a788c",
      "#c8dff8",
      "#5dbfdd"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette60",
    colors: [
      "#5dbfdd",
      "#ffffff",
      "#c8dff8",
      "#3a788c"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette61",
    colors: [
      "#5dbfdd",
      "#ffffff",
      "#3a788c",
      "#c8dff8"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette62",
    colors: [
      "#5dbfdd",
      "#c8dff8",
      "#ffffff",
      "#3a788c"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette63",
    colors: [
      "#5dbfdd",
      "#c8dff8",
      "#3a788c",
      "#ffffff"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette64",
    colors: [
      "#5dbfdd",
      "#3a788c",
      "#ffffff",
      "#c8dff8"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette65",
    colors: [
      "#5dbfdd",
      "#3a788c",
      "#c8dff8",
      "#ffffff"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette66",
    colors: [
      "#c8dff8",
      "#ffffff",
      "#5dbfdd",
      "#3a788c"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette67",
    colors: [
      "#c8dff8",
      "#ffffff",
      "#3a788c",
      "#5dbfdd"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette68",
    colors: [
      "#c8dff8",
      "#5dbfdd",
      "#ffffff",
      "#3a788c"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette69",
    colors: [
      "#c8dff8",
      "#5dbfdd",
      "#3a788c",
      "#ffffff"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette70",
    colors: [
      "#c8dff8",
      "#3a788c",
      "#ffffff",
      "#5dbfdd"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette71",
    colors: [
      "#c8dff8",
      "#3a788c",
      "#5dbfdd",
      "#ffffff"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette72",
    colors: [
      "#3a788c",
      "#ffffff",
      "#5dbfdd",
      "#c8dff8"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette73",
    colors: [
      "#3a788c",
      "#ffffff",
      "#c8dff8",
      "#5dbfdd"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette74",
    colors: [
      "#3a788c",
      "#5dbfdd",
      "#ffffff",
      "#c8dff8"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette75",
    colors: [
      "#3a788c",
      "#5dbfdd",
      "#c8dff8",
      "#ffffff"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette76",
    colors: [
      "#3a788c",
      "#c8dff8",
      "#ffffff",
      "#5dbfdd"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette77",
    colors: [
      "#3a788c",
      "#c8dff8",
      "#5dbfdd",
      "#ffffff"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette78",
    colors: [
      "#1a1941",
      "#67a5d1",
      "#4e6ff6",
      "#a83af1",
      "#f1f1f1"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette79",
    colors: [
      "#1a1941",
      "#67a5d1",
      "#4e6ff6",
      "#f1f1f1",
      "#a83af1"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette80",
    colors: [
      "#1a1941",
      "#67a5d1",
      "#a83af1",
      "#4e6ff6",
      "#f1f1f1"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette81",
    colors: [
      "#1a1941",
      "#67a5d1",
      "#a83af1",
      "#f1f1f1",
      "#4e6ff6"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette82",
    colors: [
      "#1a1941",
      "#67a5d1",
      "#f1f1f1",
      "#4e6ff6",
      "#a83af1"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette83",
    colors: [
      "#1a1941",
      "#67a5d1",
      "#f1f1f1",
      "#a83af1",
      "#4e6ff6"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette84",
    colors: [
      "#1a1941",
      "#4e6ff6",
      "#67a5d1",
      "#a83af1",
      "#f1f1f1"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette85",
    colors: [
      "#1a1941",
      "#4e6ff6",
      "#67a5d1",
      "#f1f1f1",
      "#a83af1"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette86",
    colors: [
      "#1a1941",
      "#4e6ff6",
      "#a83af1",
      "#67a5d1",
      "#f1f1f1"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette87",
    colors: [
      "#1a1941",
      "#4e6ff6",
      "#a83af1",
      "#f1f1f1",
      "#67a5d1"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette88",
    colors: [
      "#1a1941",
      "#4e6ff6",
      "#f1f1f1",
      "#67a5d1",
      "#a83af1"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette89",
    colors: [
      "#1a1941",
      "#4e6ff6",
      "#f1f1f1",
      "#a83af1",
      "#67a5d1"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette90",
    colors: [
      "#1a1941",
      "#a83af1",
      "#67a5d1",
      "#4e6ff6",
      "#f1f1f1"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette91",
    colors: [
      "#1a1941",
      "#a83af1",
      "#67a5d1",
      "#f1f1f1",
      "#4e6ff6"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette92",
    colors: [
      "#1a1941",
      "#a83af1",
      "#4e6ff6",
      "#67a5d1",
      "#f1f1f1"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette93",
    colors: [
      "#1a1941",
      "#a83af1",
      "#4e6ff6",
      "#f1f1f1",
      "#67a5d1"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette94",
    colors: [
      "#1a1941",
      "#a83af1",
      "#f1f1f1",
      "#67a5d1",
      "#4e6ff6"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette95",
    colors: [
      "#1a1941",
      "#a83af1",
      "#f1f1f1",
      "#4e6ff6",
      "#67a5d1"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette96",
    colors: [
      "#1a1941",
      "#f1f1f1",
      "#67a5d1",
      "#4e6ff6",
      "#a83af1"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette97",
    colors: [
      "#1a1941",
      "#f1f1f1",
      "#67a5d1",
      "#a83af1",
      "#4e6ff6"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette98",
    colors: [
      "#1a1941",
      "#f1f1f1",
      "#4e6ff6",
      "#67a5d1",
      "#a83af1"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette99",
    colors: [
      "#1a1941",
      "#f1f1f1",
      "#4e6ff6",
      "#a83af1",
      "#67a5d1"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette100",
    colors: [
      "#1a1941",
      "#f1f1f1",
      "#a83af1",
      "#67a5d1",
      "#4e6ff6"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette101",
    colors: [
      "#1a1941",
      "#f1f1f1",
      "#a83af1",
      "#4e6ff6",
      "#67a5d1"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette102",
    colors: [
      "#67a5d1",
      "#1a1941",
      "#4e6ff6",
      "#a83af1",
      "#f1f1f1"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette103",
    colors: [
      "#67a5d1",
      "#1a1941",
      "#4e6ff6",
      "#f1f1f1",
      "#a83af1"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette104",
    colors: [
      "#67a5d1",
      "#1a1941",
      "#a83af1",
      "#4e6ff6",
      "#f1f1f1"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette105",
    colors: [
      "#67a5d1",
      "#1a1941",
      "#a83af1",
      "#f1f1f1",
      "#4e6ff6"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette106",
    colors: [
      "#67a5d1",
      "#1a1941",
      "#f1f1f1",
      "#4e6ff6",
      "#a83af1"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette107",
    colors: [
      "#67a5d1",
      "#1a1941",
      "#f1f1f1",
      "#a83af1",
      "#4e6ff6"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette108",
    colors: [
      "#67a5d1",
      "#4e6ff6",
      "#1a1941",
      "#a83af1",
      "#f1f1f1"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette109",
    colors: [
      "#67a5d1",
      "#4e6ff6",
      "#1a1941",
      "#f1f1f1",
      "#a83af1"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette110",
    colors: [
      "#67a5d1",
      "#4e6ff6",
      "#a83af1",
      "#1a1941",
      "#f1f1f1"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette111",
    colors: [
      "#67a5d1",
      "#4e6ff6",
      "#a83af1",
      "#f1f1f1",
      "#1a1941"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette112",
    colors: [
      "#67a5d1",
      "#4e6ff6",
      "#f1f1f1",
      "#1a1941",
      "#a83af1"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette113",
    colors: [
      "#67a5d1",
      "#4e6ff6",
      "#f1f1f1",
      "#a83af1",
      "#1a1941"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette114",
    colors: [
      "#67a5d1",
      "#a83af1",
      "#1a1941",
      "#4e6ff6",
      "#f1f1f1"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette115",
    colors: [
      "#67a5d1",
      "#a83af1",
      "#1a1941",
      "#f1f1f1",
      "#4e6ff6"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette116",
    colors: [
      "#67a5d1",
      "#a83af1",
      "#4e6ff6",
      "#1a1941",
      "#f1f1f1"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette117",
    colors: [
      "#67a5d1",
      "#a83af1",
      "#4e6ff6",
      "#f1f1f1",
      "#1a1941"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette118",
    colors: [
      "#67a5d1",
      "#a83af1",
      "#f1f1f1",
      "#1a1941",
      "#4e6ff6"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette119",
    colors: [
      "#67a5d1",
      "#a83af1",
      "#f1f1f1",
      "#4e6ff6",
      "#1a1941"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette120",
    colors: [
      "#67a5d1",
      "#f1f1f1",
      "#1a1941",
      "#4e6ff6",
      "#a83af1"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette121",
    colors: [
      "#67a5d1",
      "#f1f1f1",
      "#1a1941",
      "#a83af1",
      "#4e6ff6"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette122",
    colors: [
      "#67a5d1",
      "#f1f1f1",
      "#4e6ff6",
      "#1a1941",
      "#a83af1"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette123",
    colors: [
      "#67a5d1",
      "#f1f1f1",
      "#4e6ff6",
      "#a83af1",
      "#1a1941"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette124",
    colors: [
      "#67a5d1",
      "#f1f1f1",
      "#a83af1",
      "#1a1941",
      "#4e6ff6"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette125",
    colors: [
      "#67a5d1",
      "#f1f1f1",
      "#a83af1",
      "#4e6ff6",
      "#1a1941"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette126",
    colors: [
      "#4e6ff6",
      "#1a1941",
      "#67a5d1",
      "#a83af1",
      "#f1f1f1"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette127",
    colors: [
      "#4e6ff6",
      "#1a1941",
      "#67a5d1",
      "#f1f1f1",
      "#a83af1"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette128",
    colors: [
      "#4e6ff6",
      "#1a1941",
      "#a83af1",
      "#67a5d1",
      "#f1f1f1"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette129",
    colors: [
      "#4e6ff6",
      "#1a1941",
      "#a83af1",
      "#f1f1f1",
      "#67a5d1"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette130",
    colors: [
      "#4e6ff6",
      "#1a1941",
      "#f1f1f1",
      "#67a5d1",
      "#a83af1"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette131",
    colors: [
      "#4e6ff6",
      "#1a1941",
      "#f1f1f1",
      "#a83af1",
      "#67a5d1"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette132",
    colors: [
      "#4e6ff6",
      "#67a5d1",
      "#1a1941",
      "#a83af1",
      "#f1f1f1"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette133",
    colors: [
      "#4e6ff6",
      "#67a5d1",
      "#1a1941",
      "#f1f1f1",
      "#a83af1"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette134",
    colors: [
      "#4e6ff6",
      "#67a5d1",
      "#a83af1",
      "#1a1941",
      "#f1f1f1"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette135",
    colors: [
      "#4e6ff6",
      "#67a5d1",
      "#a83af1",
      "#f1f1f1",
      "#1a1941"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette136",
    colors: [
      "#4e6ff6",
      "#67a5d1",
      "#f1f1f1",
      "#1a1941",
      "#a83af1"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette137",
    colors: [
      "#4e6ff6",
      "#67a5d1",
      "#f1f1f1",
      "#a83af1",
      "#1a1941"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette144",
    colors: [
      "#4e6ff6",
      "#f1f1f1",
      "#1a1941",
      "#67a5d1",
      "#a83af1"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette145",
    colors: [
      "#4e6ff6",
      "#f1f1f1",
      "#1a1941",
      "#a83af1",
      "#67a5d1"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette146",
    colors: [
      "#4e6ff6",
      "#f1f1f1",
      "#67a5d1",
      "#1a1941",
      "#a83af1"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette148",
    colors: [
      "#4e6ff6",
      "#f1f1f1",
      "#a83af1",
      "#1a1941",
      "#67a5d1"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette149",
    colors: [
      "#4e6ff6",
      "#f1f1f1",
      "#a83af1",
      "#67a5d1",
      "#1a1941"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette150",
    colors: [
      "#a83af1",
      "#1a1941",
      "#67a5d1",
      "#4e6ff6",
      "#f1f1f1"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette151",
    colors: [
      "#a83af1",
      "#1a1941",
      "#67a5d1",
      "#f1f1f1",
      "#4e6ff6"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette153",
    colors: [
      "#a83af1",
      "#1a1941",
      "#4e6ff6",
      "#f1f1f1",
      "#67a5d1"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette154",
    colors: [
      "#a83af1",
      "#1a1941",
      "#f1f1f1",
      "#67a5d1",
      "#4e6ff6"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette158",
    colors: [
      "#a83af1",
      "#67a5d1",
      "#4e6ff6",
      "#1a1941",
      "#f1f1f1"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette160",
    colors: [
      "#a83af1",
      "#67a5d1",
      "#f1f1f1",
      "#1a1941",
      "#4e6ff6"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette168",
    colors: [
      "#a83af1",
      "#f1f1f1",
      "#1a1941",
      "#67a5d1",
      "#4e6ff6"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette170",
    colors: [
      "#a83af1",
      "#f1f1f1",
      "#67a5d1",
      "#1a1941",
      "#4e6ff6"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette171",
    colors: [
      "#a83af1",
      "#f1f1f1",
      "#67a5d1",
      "#4e6ff6",
      "#1a1941"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette172",
    colors: [
      "#a83af1",
      "#f1f1f1",
      "#4e6ff6",
      "#1a1941",
      "#67a5d1"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette173",
    colors: [
      "#a83af1",
      "#f1f1f1",
      "#4e6ff6",
      "#67a5d1",
      "#1a1941"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette174",
    colors: [
      "#f1f1f1",
      "#1a1941",
      "#67a5d1",
      "#4e6ff6",
      "#a83af1"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette175",
    colors: [
      "#f1f1f1",
      "#1a1941",
      "#67a5d1",
      "#a83af1",
      "#4e6ff6"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette176",
    colors: [
      "#f1f1f1",
      "#1a1941",
      "#4e6ff6",
      "#67a5d1",
      "#a83af1"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette177",
    colors: [
      "#f1f1f1",
      "#1a1941",
      "#4e6ff6",
      "#a83af1",
      "#67a5d1"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette178",
    colors: [
      "#f1f1f1",
      "#1a1941",
      "#a83af1",
      "#67a5d1",
      "#4e6ff6"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette179",
    colors: [
      "#f1f1f1",
      "#1a1941",
      "#a83af1",
      "#4e6ff6",
      "#67a5d1"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette180",
    colors: [
      "#f1f1f1",
      "#67a5d1",
      "#1a1941",
      "#4e6ff6",
      "#a83af1"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette181",
    colors: [
      "#f1f1f1",
      "#67a5d1",
      "#1a1941",
      "#a83af1",
      "#4e6ff6"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette182",
    colors: [
      "#f1f1f1",
      "#67a5d1",
      "#4e6ff6",
      "#1a1941",
      "#a83af1"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette183",
    colors: [
      "#f1f1f1",
      "#67a5d1",
      "#4e6ff6",
      "#a83af1",
      "#1a1941"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette184",
    colors: [
      "#f1f1f1",
      "#67a5d1",
      "#a83af1",
      "#1a1941",
      "#4e6ff6"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette185",
    colors: [
      "#f1f1f1",
      "#67a5d1",
      "#a83af1",
      "#4e6ff6",
      "#1a1941"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette186",
    colors: [
      "#f1f1f1",
      "#4e6ff6",
      "#1a1941",
      "#67a5d1",
      "#a83af1"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette187",
    colors: [
      "#f1f1f1",
      "#4e6ff6",
      "#1a1941",
      "#a83af1",
      "#67a5d1"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette188",
    colors: [
      "#f1f1f1",
      "#4e6ff6",
      "#67a5d1",
      "#1a1941",
      "#a83af1"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette189",
    colors: [
      "#f1f1f1",
      "#4e6ff6",
      "#67a5d1",
      "#a83af1",
      "#1a1941"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette190",
    colors: [
      "#f1f1f1",
      "#4e6ff6",
      "#a83af1",
      "#1a1941",
      "#67a5d1"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette191",
    colors: [
      "#f1f1f1",
      "#4e6ff6",
      "#a83af1",
      "#67a5d1",
      "#1a1941"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette192",
    colors: [
      "#f1f1f1",
      "#a83af1",
      "#1a1941",
      "#67a5d1",
      "#4e6ff6"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette193",
    colors: [
      "#f1f1f1",
      "#a83af1",
      "#1a1941",
      "#4e6ff6",
      "#67a5d1"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette194",
    colors: [
      "#f1f1f1",
      "#a83af1",
      "#67a5d1",
      "#1a1941",
      "#4e6ff6"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette195",
    colors: [
      "#f1f1f1",
      "#a83af1",
      "#67a5d1",
      "#4e6ff6",
      "#1a1941"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette196",
    colors: [
      "#f1f1f1",
      "#a83af1",
      "#4e6ff6",
      "#1a1941",
      "#67a5d1"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette197",
    colors: [
      "#f1f1f1",
      "#a83af1",
      "#4e6ff6",
      "#67a5d1",
      "#1a1941"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette199",
    colors: [
      "#f7d170",
      "#1c224b",
      "#ffffff",
      "#000000"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette200",
    colors: [
      "#f7d170",
      "#000000",
      "#1c224b",
      "#ffffff"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette202",
    colors: [
      "#f7d170",
      "#ffffff",
      "#1c224b",
      "#000000"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette204",
    colors: [
      "#1c224b",
      "#f7d170",
      "#000000",
      "#ffffff"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette205",
    colors: [
      "#1c224b",
      "#f7d170",
      "#ffffff",
      "#000000"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette206",
    colors: [
      "#1c224b",
      "#000000",
      "#f7d170",
      "#ffffff"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette208",
    colors: [
      "#1c224b",
      "#ffffff",
      "#f7d170",
      "#000000"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette209",
    colors: [
      "#1c224b",
      "#ffffff",
      "#000000",
      "#f7d170"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette210",
    colors: [
      "#000000",
      "#f7d170",
      "#1c224b",
      "#ffffff"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette211",
    colors: [
      "#000000",
      "#f7d170",
      "#ffffff",
      "#1c224b"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette212",
    colors: [
      "#000000",
      "#1c224b",
      "#f7d170",
      "#ffffff"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette215",
    colors: [
      "#000000",
      "#ffffff",
      "#1c224b",
      "#f7d170"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette216",
    colors: [
      "#ffffff",
      "#f7d170",
      "#1c224b",
      "#000000"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette218",
    colors: [
      "#ffffff",
      "#1c224b",
      "#f7d170",
      "#000000"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette219",
    colors: [
      "#ffffff",
      "#1c224b",
      "#000000",
      "#f7d170"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette220",
    colors: [
      "#ffffff",
      "#000000",
      "#f7d170",
      "#1c224b"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette222",
    colors: [
      "#e4e4e4",
      "#4e3755",
      "#52aae4",
      "#53a855",
      "#e5e355"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette224",
    colors: [
      "#e4e4e4",
      "#4e3755",
      "#53a855",
      "#52aae4",
      "#e5e355"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette226",
    colors: [
      "#e4e4e4",
      "#4e3755",
      "#e5e355",
      "#52aae4",
      "#53a855"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette227",
    colors: [
      "#e4e4e4",
      "#4e3755",
      "#e5e355",
      "#53a855",
      "#52aae4"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette228",
    colors: [
      "#e4e4e4",
      "#52aae4",
      "#4e3755",
      "#53a855",
      "#e5e355"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette230",
    colors: [
      "#e4e4e4",
      "#52aae4",
      "#53a855",
      "#4e3755",
      "#e5e355"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette232",
    colors: [
      "#e4e4e4",
      "#52aae4",
      "#e5e355",
      "#4e3755",
      "#53a855"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette233",
    colors: [
      "#e4e4e4",
      "#52aae4",
      "#e5e355",
      "#53a855",
      "#4e3755"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette234",
    colors: [
      "#e4e4e4",
      "#53a855",
      "#4e3755",
      "#52aae4",
      "#e5e355"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette236",
    colors: [
      "#e4e4e4",
      "#53a855",
      "#52aae4",
      "#4e3755",
      "#e5e355"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette238",
    colors: [
      "#e4e4e4",
      "#53a855",
      "#e5e355",
      "#4e3755",
      "#52aae4"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette239",
    colors: [
      "#e4e4e4",
      "#53a855",
      "#e5e355",
      "#52aae4",
      "#4e3755"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette246",
    colors: [
      "#4e3755",
      "#e4e4e4",
      "#52aae4",
      "#53a855",
      "#e5e355"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette247",
    colors: [
      "#4e3755",
      "#e4e4e4",
      "#52aae4",
      "#e5e355",
      "#53a855"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette248",
    colors: [
      "#4e3755",
      "#e4e4e4",
      "#53a855",
      "#52aae4",
      "#e5e355"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette252",
    colors: [
      "#4e3755",
      "#52aae4",
      "#e4e4e4",
      "#53a855",
      "#e5e355"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette253",
    colors: [
      "#4e3755",
      "#52aae4",
      "#e4e4e4",
      "#e5e355",
      "#53a855"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette256",
    colors: [
      "#4e3755",
      "#52aae4",
      "#e5e355",
      "#e4e4e4",
      "#53a855"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette257",
    colors: [
      "#4e3755",
      "#52aae4",
      "#e5e355",
      "#53a855",
      "#e4e4e4"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette258",
    colors: [
      "#4e3755",
      "#53a855",
      "#e4e4e4",
      "#52aae4",
      "#e5e355"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette259",
    colors: [
      "#4e3755",
      "#53a855",
      "#e4e4e4",
      "#e5e355",
      "#52aae4"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette262",
    colors: [
      "#4e3755",
      "#53a855",
      "#e5e355",
      "#e4e4e4",
      "#52aae4"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette263",
    colors: [
      "#4e3755",
      "#53a855",
      "#e5e355",
      "#52aae4",
      "#e4e4e4"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette267",
    colors: [
      "#4e3755",
      "#e5e355",
      "#52aae4",
      "#53a855",
      "#e4e4e4"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette269",
    colors: [
      "#4e3755",
      "#e5e355",
      "#53a855",
      "#52aae4",
      "#e4e4e4"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette270",
    colors: [
      "#52aae4",
      "#e4e4e4",
      "#4e3755",
      "#53a855",
      "#e5e355"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette271",
    colors: [
      "#52aae4",
      "#e4e4e4",
      "#4e3755",
      "#e5e355",
      "#53a855"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette272",
    colors: [
      "#52aae4",
      "#e4e4e4",
      "#53a855",
      "#4e3755",
      "#e5e355"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette273",
    colors: [
      "#52aae4",
      "#e4e4e4",
      "#53a855",
      "#e5e355",
      "#4e3755"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette276",
    colors: [
      "#52aae4",
      "#4e3755",
      "#e4e4e4",
      "#53a855",
      "#e5e355"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette278",
    colors: [
      "#52aae4",
      "#4e3755",
      "#53a855",
      "#e4e4e4",
      "#e5e355"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette279",
    colors: [
      "#52aae4",
      "#4e3755",
      "#53a855",
      "#e5e355",
      "#e4e4e4"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette280",
    colors: [
      "#52aae4",
      "#4e3755",
      "#e5e355",
      "#e4e4e4",
      "#53a855"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette281",
    colors: [
      "#52aae4",
      "#4e3755",
      "#e5e355",
      "#53a855",
      "#e4e4e4"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette290",
    colors: [
      "#52aae4",
      "#e5e355",
      "#4e3755",
      "#e4e4e4",
      "#53a855"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette291",
    colors: [
      "#52aae4",
      "#e5e355",
      "#4e3755",
      "#53a855",
      "#e4e4e4"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette292",
    colors: [
      "#52aae4",
      "#e5e355",
      "#53a855",
      "#e4e4e4",
      "#4e3755"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette293",
    colors: [
      "#52aae4",
      "#e5e355",
      "#53a855",
      "#4e3755",
      "#e4e4e4"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette295",
    colors: [
      "#53a855",
      "#e4e4e4",
      "#4e3755",
      "#e5e355",
      "#52aae4"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette296",
    colors: [
      "#53a855",
      "#e4e4e4",
      "#52aae4",
      "#4e3755",
      "#e5e355"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette297",
    colors: [
      "#53a855",
      "#e4e4e4",
      "#52aae4",
      "#e5e355",
      "#4e3755"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette298",
    colors: [
      "#53a855",
      "#e4e4e4",
      "#e5e355",
      "#4e3755",
      "#52aae4"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette301",
    colors: [
      "#53a855",
      "#4e3755",
      "#e4e4e4",
      "#e5e355",
      "#52aae4"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette302",
    colors: [
      "#53a855",
      "#4e3755",
      "#52aae4",
      "#e4e4e4",
      "#e5e355"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette303",
    colors: [
      "#53a855",
      "#4e3755",
      "#52aae4",
      "#e5e355",
      "#e4e4e4"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette304",
    colors: [
      "#53a855",
      "#4e3755",
      "#e5e355",
      "#e4e4e4",
      "#52aae4"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette306",
    colors: [
      "#53a855",
      "#52aae4",
      "#e4e4e4",
      "#4e3755",
      "#e5e355"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette307",
    colors: [
      "#53a855",
      "#52aae4",
      "#e4e4e4",
      "#e5e355",
      "#4e3755"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette308",
    colors: [
      "#53a855",
      "#52aae4",
      "#4e3755",
      "#e4e4e4",
      "#e5e355"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette309",
    colors: [
      "#53a855",
      "#52aae4",
      "#4e3755",
      "#e5e355",
      "#e4e4e4"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette317",
    colors: [
      "#53a855",
      "#e5e355",
      "#52aae4",
      "#4e3755",
      "#e4e4e4"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette324",
    colors: [
      "#e5e355",
      "#4e3755",
      "#e4e4e4",
      "#52aae4",
      "#53a855"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette325",
    colors: [
      "#e5e355",
      "#4e3755",
      "#e4e4e4",
      "#53a855",
      "#52aae4"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette327",
    colors: [
      "#e5e355",
      "#4e3755",
      "#52aae4",
      "#53a855",
      "#e4e4e4"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette329",
    colors: [
      "#e5e355",
      "#4e3755",
      "#53a855",
      "#52aae4",
      "#e4e4e4"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette330",
    colors: [
      "#e5e355",
      "#52aae4",
      "#e4e4e4",
      "#4e3755",
      "#53a855"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette331",
    colors: [
      "#e5e355",
      "#52aae4",
      "#e4e4e4",
      "#53a855",
      "#4e3755"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette332",
    colors: [
      "#e5e355",
      "#52aae4",
      "#4e3755",
      "#e4e4e4",
      "#53a855"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette333",
    colors: [
      "#e5e355",
      "#52aae4",
      "#4e3755",
      "#53a855",
      "#e4e4e4"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette335",
    colors: [
      "#e5e355",
      "#52aae4",
      "#53a855",
      "#4e3755",
      "#e4e4e4"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette336",
    colors: [
      "#e5e355",
      "#53a855",
      "#e4e4e4",
      "#4e3755",
      "#52aae4"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette337",
    colors: [
      "#e5e355",
      "#53a855",
      "#e4e4e4",
      "#52aae4",
      "#4e3755"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette339",
    colors: [
      "#e5e355",
      "#53a855",
      "#4e3755",
      "#52aae4",
      "#e4e4e4"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette341",
    colors: [
      "#e5e355",
      "#53a855",
      "#52aae4",
      "#4e3755",
      "#e4e4e4"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette342",
    colors: [
      "#14171d",
      "#ee955e",
      "#e9673d",
      "#ffffff"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette343",
    colors: [
      "#14171d",
      "#ee955e",
      "#ffffff",
      "#e9673d"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette344",
    colors: [
      "#14171d",
      "#e9673d",
      "#ee955e",
      "#ffffff"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette346",
    colors: [
      "#14171d",
      "#ffffff",
      "#ee955e",
      "#e9673d"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette347",
    colors: [
      "#14171d",
      "#ffffff",
      "#e9673d",
      "#ee955e"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette348",
    colors: [
      "#ee955e",
      "#14171d",
      "#e9673d",
      "#ffffff"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette349",
    colors: [
      "#ee955e",
      "#14171d",
      "#ffffff",
      "#e9673d"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette352",
    colors: [
      "#ee955e",
      "#ffffff",
      "#14171d",
      "#e9673d"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette353",
    colors: [
      "#ee955e",
      "#ffffff",
      "#e9673d",
      "#14171d"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette354",
    colors: [
      "#e9673d",
      "#14171d",
      "#ee955e",
      "#ffffff"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette355",
    colors: [
      "#e9673d",
      "#14171d",
      "#ffffff",
      "#ee955e"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette356",
    colors: [
      "#e9673d",
      "#ee955e",
      "#14171d",
      "#ffffff"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette357",
    colors: [
      "#e9673d",
      "#ee955e",
      "#ffffff",
      "#14171d"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette358",
    colors: [
      "#e9673d",
      "#ffffff",
      "#14171d",
      "#ee955e"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette359",
    colors: [
      "#e9673d",
      "#ffffff",
      "#ee955e",
      "#14171d"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette360",
    colors: [
      "#ffffff",
      "#14171d",
      "#ee955e",
      "#e9673d"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette361",
    colors: [
      "#ffffff",
      "#14171d",
      "#e9673d",
      "#ee955e"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette362",
    colors: [
      "#ffffff",
      "#ee955e",
      "#14171d",
      "#e9673d"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette363",
    colors: [
      "#ffffff",
      "#ee955e",
      "#e9673d",
      "#14171d"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette364",
    colors: [
      "#ffffff",
      "#e9673d",
      "#14171d",
      "#ee955e"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette365",
    colors: [
      "#ffffff",
      "#e9673d",
      "#ee955e",
      "#14171d"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette369",
    colors: [
      "#ff3931",
      "#311f27",
      "#007861",
      "#bab9a4"
    ],
    size: 4,
    background: "#dddddd"
  },
  {
    name: "palette370",
    colors: [
      "#ff3931",
      "#311f27",
      "#bab9a4",
      "#007861"
    ],
    size: 4,
    background: "#dddddd"
  },
  {
    name: "palette371",
    colors: [
      "#ff3931",
      "#bab9a4",
      "#007861",
      "#311f27"
    ],
    size: 4,
    background: "#dddddd"
  },
  {
    name: "palette372",
    colors: [
      "#ff3931",
      "#bab9a4",
      "#311f27",
      "#007861"
    ],
    size: 4,
    background: "#dddddd"
  },
  {
    name: "palette373",
    colors: [
      "#007861",
      "#ff3931",
      "#311f27",
      "#bab9a4"
    ],
    size: 4,
    background: "#dddddd"
  },
  {
    name: "palette374",
    colors: [
      "#007861",
      "#ff3931",
      "#bab9a4",
      "#311f27"
    ],
    size: 4,
    background: "#dddddd"
  },
  {
    name: "palette375",
    colors: [
      "#007861",
      "#311f27",
      "#ff3931",
      "#bab9a4"
    ],
    size: 4,
    background: "#dddddd"
  },
  {
    name: "palette376",
    colors: [
      "#007861",
      "#311f27",
      "#bab9a4",
      "#ff3931"
    ],
    size: 4,
    background: "#dddddd"
  },
  {
    name: "palette377",
    colors: [
      "#007861",
      "#bab9a4",
      "#ff3931",
      "#311f27"
    ],
    size: 4,
    background: "#dddddd"
  },
  {
    name: "palette378",
    colors: [
      "#007861",
      "#bab9a4",
      "#311f27",
      "#ff3931"
    ],
    size: 4,
    background: "#dddddd"
  },
  {
    name: "palette379",
    colors: [
      "#311f27",
      "#ff3931",
      "#007861",
      "#bab9a4"
    ],
    size: 4,
    background: "#dddddd"
  },
  {
    name: "palette380",
    colors: [
      "#311f27",
      "#ff3931",
      "#bab9a4",
      "#007861"
    ],
    size: 4,
    background: "#dddddd"
  },
  {
    name: "palette381",
    colors: [
      "#311f27",
      "#007861",
      "#ff3931",
      "#bab9a4"
    ],
    size: 4,
    background: "#dddddd"
  },
  {
    name: "palette382",
    colors: [
      "#311f27",
      "#007861",
      "#bab9a4",
      "#ff3931"
    ],
    size: 4,
    background: "#dddddd"
  },
  {
    name: "palette383",
    colors: [
      "#311f27",
      "#bab9a4",
      "#ff3931",
      "#007861"
    ],
    size: 4,
    background: "#dddddd"
  },
  {
    name: "palette384",
    colors: [
      "#311f27",
      "#bab9a4",
      "#007861",
      "#ff3931"
    ],
    size: 4,
    background: "#dddddd"
  },
  {
    name: "palette385",
    colors: [
      "#bab9a4",
      "#ff3931",
      "#007861",
      "#311f27"
    ],
    size: 4,
    background: "#dddddd"
  },
  {
    name: "palette386",
    colors: [
      "#bab9a4",
      "#ff3931",
      "#311f27",
      "#007861"
    ],
    size: 4,
    background: "#dddddd"
  },
  {
    name: "palette387",
    colors: [
      "#bab9a4",
      "#007861",
      "#ff3931",
      "#311f27"
    ],
    size: 4,
    background: "#dddddd"
  },
  {
    name: "palette388",
    colors: [
      "#bab9a4",
      "#007861",
      "#311f27",
      "#ff3931"
    ],
    size: 4,
    background: "#dddddd"
  },
  {
    name: "palette389",
    colors: [
      "#bab9a4",
      "#311f27",
      "#ff3931",
      "#007861"
    ],
    size: 4,
    background: "#dddddd"
  },
  {
    name: "palette390",
    colors: [
      "#bab9a4",
      "#311f27",
      "#007861",
      "#ff3931"
    ],
    size: 4,
    background: "#dddddd"
  },
  {
    name: "palette391",
    colors: [
      "#004996",
      "#567bae",
      "#ff4c48",
      "#ffbcb3"
    ],
    size: 4,
    background: "#fff8e7"
  },
  {
    name: "palette392",
    colors: [
      "#004996",
      "#567bae",
      "#ffbcb3",
      "#ff4c48"
    ],
    size: 4,
    background: "#fff8e7"
  },
  {
    name: "palette395",
    colors: [
      "#004996",
      "#ffbcb3",
      "#567bae",
      "#ff4c48"
    ],
    size: 4,
    background: "#fff8e7"
  },
  {
    name: "palette396",
    colors: [
      "#004996",
      "#ffbcb3",
      "#ff4c48",
      "#567bae"
    ],
    size: 4,
    background: "#fff8e7"
  },
  {
    name: "palette397",
    colors: [
      "#567bae",
      "#004996",
      "#ff4c48",
      "#ffbcb3"
    ],
    size: 4,
    background: "#fff8e7"
  },
  {
    name: "palette398",
    colors: [
      "#567bae",
      "#004996",
      "#ffbcb3",
      "#ff4c48"
    ],
    size: 4,
    background: "#fff8e7"
  },
  {
    name: "palette399",
    colors: [
      "#567bae",
      "#ff4c48",
      "#004996",
      "#ffbcb3"
    ],
    size: 4,
    background: "#fff8e7"
  },
  {
    name: "palette400",
    colors: [
      "#567bae",
      "#ff4c48",
      "#ffbcb3",
      "#004996"
    ],
    size: 4,
    background: "#fff8e7"
  },
  {
    name: "palette401",
    colors: [
      "#567bae",
      "#ffbcb3",
      "#004996",
      "#ff4c48"
    ],
    size: 4,
    background: "#fff8e7"
  },
  {
    name: "palette402",
    colors: [
      "#567bae",
      "#ffbcb3",
      "#ff4c48",
      "#004996"
    ],
    size: 4,
    background: "#fff8e7"
  },
  {
    name: "palette403",
    colors: [
      "#ff4c48",
      "#004996",
      "#567bae",
      "#ffbcb3"
    ],
    size: 4,
    background: "#fff8e7"
  },
  {
    name: "palette404",
    colors: [
      "#ff4c48",
      "#004996",
      "#ffbcb3",
      "#567bae"
    ],
    size: 4,
    background: "#fff8e7"
  },
  {
    name: "palette405",
    colors: [
      "#ff4c48",
      "#567bae",
      "#004996",
      "#ffbcb3"
    ],
    size: 4,
    background: "#fff8e7"
  },
  {
    name: "palette406",
    colors: [
      "#ff4c48",
      "#567bae",
      "#ffbcb3",
      "#004996"
    ],
    size: 4,
    background: "#fff8e7"
  },
  {
    name: "palette407",
    colors: [
      "#ff4c48",
      "#ffbcb3",
      "#004996",
      "#567bae"
    ],
    size: 4,
    background: "#fff8e7"
  },
  {
    name: "palette408",
    colors: [
      "#ff4c48",
      "#ffbcb3",
      "#567bae",
      "#004996"
    ],
    size: 4,
    background: "#fff8e7"
  },
  {
    name: "palette409",
    colors: [
      "#ffbcb3",
      "#004996",
      "#567bae",
      "#ff4c48"
    ],
    size: 4,
    background: "#fff8e7"
  },
  {
    name: "palette410",
    colors: [
      "#ffbcb3",
      "#004996",
      "#ff4c48",
      "#567bae"
    ],
    size: 4,
    background: "#fff8e7"
  },
  {
    name: "palette411",
    colors: [
      "#ffbcb3",
      "#567bae",
      "#004996",
      "#ff4c48"
    ],
    size: 4,
    background: "#fff8e7"
  },
  {
    name: "palette412",
    colors: [
      "#ffbcb3",
      "#567bae",
      "#ff4c48",
      "#004996"
    ],
    size: 4,
    background: "#fff8e7"
  },
  {
    name: "palette413",
    colors: [
      "#ffbcb3",
      "#ff4c48",
      "#004996",
      "#567bae"
    ],
    size: 4,
    background: "#fff8e7"
  },
  {
    name: "palette414",
    colors: [
      "#ffbcb3",
      "#ff4c48",
      "#567bae",
      "#004996"
    ],
    size: 4,
    background: "#fff8e7"
  },
  {
    name: "palette417",
    colors: [
      "#809498",
      "#000000",
      "#d3990e",
      "#ecddc5"
    ],
    size: 4,
    background: "#863f52"
  },
  {
    name: "palette418",
    colors: [
      "#809498",
      "#000000",
      "#ecddc5",
      "#d3990e"
    ],
    size: 4,
    background: "#863f52"
  },
  {
    name: "palette419",
    colors: [
      "#809498",
      "#ecddc5",
      "#d3990e",
      "#000000"
    ],
    size: 4,
    background: "#863f52"
  },
  {
    name: "palette420",
    colors: [
      "#809498",
      "#ecddc5",
      "#000000",
      "#d3990e"
    ],
    size: 4,
    background: "#863f52"
  },
  {
    name: "palette423",
    colors: [
      "#d3990e",
      "#000000",
      "#809498",
      "#ecddc5"
    ],
    size: 4,
    background: "#863f52"
  },
  {
    name: "palette424",
    colors: [
      "#d3990e",
      "#000000",
      "#ecddc5",
      "#809498"
    ],
    size: 4,
    background: "#863f52"
  },
  {
    name: "palette425",
    colors: [
      "#d3990e",
      "#ecddc5",
      "#809498",
      "#000000"
    ],
    size: 4,
    background: "#863f52"
  },
  {
    name: "palette426",
    colors: [
      "#d3990e",
      "#ecddc5",
      "#000000",
      "#809498"
    ],
    size: 4,
    background: "#863f52"
  },
  {
    name: "palette427",
    colors: [
      "#000000",
      "#809498",
      "#d3990e",
      "#ecddc5"
    ],
    size: 4,
    background: "#863f52"
  },
  {
    name: "palette428",
    colors: [
      "#000000",
      "#809498",
      "#ecddc5",
      "#d3990e"
    ],
    size: 4,
    background: "#863f52"
  },
  {
    name: "palette430",
    colors: [
      "#000000",
      "#d3990e",
      "#ecddc5",
      "#809498"
    ],
    size: 4,
    background: "#863f52"
  },
  {
    name: "palette431",
    colors: [
      "#000000",
      "#ecddc5",
      "#809498",
      "#d3990e"
    ],
    size: 4,
    background: "#863f52"
  },
  {
    name: "palette432",
    colors: [
      "#000000",
      "#ecddc5",
      "#d3990e",
      "#809498"
    ],
    size: 4,
    background: "#863f52"
  },
  {
    name: "palette433",
    colors: [
      "#ecddc5",
      "#809498",
      "#d3990e",
      "#000000"
    ],
    size: 4,
    background: "#863f52"
  },
  {
    name: "palette434",
    colors: [
      "#ecddc5",
      "#809498",
      "#000000",
      "#d3990e"
    ],
    size: 4,
    background: "#863f52"
  },
  {
    name: "palette435",
    colors: [
      "#ecddc5",
      "#d3990e",
      "#809498",
      "#000000"
    ],
    size: 4,
    background: "#863f52"
  },
  {
    name: "palette436",
    colors: [
      "#ecddc5",
      "#d3990e",
      "#000000",
      "#809498"
    ],
    size: 4,
    background: "#863f52"
  },
  {
    name: "palette437",
    colors: [
      "#ecddc5",
      "#000000",
      "#809498",
      "#d3990e"
    ],
    size: 4,
    background: "#863f52"
  },
  {
    name: "palette438",
    colors: [
      "#ecddc5",
      "#000000",
      "#d3990e",
      "#809498"
    ],
    size: 4,
    background: "#863f52"
  },
  {
    name: "palette447",
    colors: [
      "#fcd744",
      "#fa7b53",
      "#bbd444",
      "#423c6f"
    ],
    size: 4,
    background: "#faf4e4"
  },
  {
    name: "palette448",
    colors: [
      "#fcd744",
      "#fa7b53",
      "#423c6f",
      "#bbd444"
    ],
    size: 4,
    background: "#faf4e4"
  },
  {
    name: "palette449",
    colors: [
      "#fcd744",
      "#423c6f",
      "#bbd444",
      "#fa7b53"
    ],
    size: 4,
    background: "#faf4e4"
  },
  {
    name: "palette450",
    colors: [
      "#fcd744",
      "#423c6f",
      "#fa7b53",
      "#bbd444"
    ],
    size: 4,
    background: "#faf4e4"
  },
  {
    name: "palette453",
    colors: [
      "#fa7b53",
      "#fcd744",
      "#bbd444",
      "#423c6f"
    ],
    size: 4,
    background: "#faf4e4"
  },
  {
    name: "palette454",
    colors: [
      "#fa7b53",
      "#fcd744",
      "#423c6f",
      "#bbd444"
    ],
    size: 4,
    background: "#faf4e4"
  },
  {
    name: "palette455",
    colors: [
      "#fa7b53",
      "#423c6f",
      "#bbd444",
      "#fcd744"
    ],
    size: 4,
    background: "#faf4e4"
  },
  {
    name: "palette456",
    colors: [
      "#fa7b53",
      "#423c6f",
      "#fcd744",
      "#bbd444"
    ],
    size: 4,
    background: "#faf4e4"
  },
  {
    name: "palette457",
    colors: [
      "#423c6f",
      "#bbd444",
      "#fcd744",
      "#fa7b53"
    ],
    size: 4,
    background: "#faf4e4"
  },
  {
    name: "palette458",
    colors: [
      "#423c6f",
      "#bbd444",
      "#fa7b53",
      "#fcd744"
    ],
    size: 4,
    background: "#faf4e4"
  },
  {
    name: "palette460",
    colors: [
      "#423c6f",
      "#fcd744",
      "#fa7b53",
      "#bbd444"
    ],
    size: 4,
    background: "#faf4e4"
  },
  {
    name: "palette461",
    colors: [
      "#423c6f",
      "#fa7b53",
      "#bbd444",
      "#fcd744"
    ],
    size: 4,
    background: "#faf4e4"
  },
  {
    name: "palette462",
    colors: [
      "#423c6f",
      "#fa7b53",
      "#fcd744",
      "#bbd444"
    ],
    size: 4,
    background: "#faf4e4"
  },
  {
    name: "palette464",
    colors: [
      "#eab700",
      "#e64818",
      "#eecfca",
      "#2c6393"
    ],
    size: 4,
    background: "#e7e6e4"
  },
  {
    name: "palette465",
    colors: [
      "#eab700",
      "#2c6393",
      "#e64818",
      "#eecfca"
    ],
    size: 4,
    background: "#e7e6e4"
  },
  {
    name: "palette466",
    colors: [
      "#eab700",
      "#2c6393",
      "#eecfca",
      "#e64818"
    ],
    size: 4,
    background: "#e7e6e4"
  },
  {
    name: "palette469",
    colors: [
      "#e64818",
      "#eab700",
      "#2c6393",
      "#eecfca"
    ],
    size: 4,
    background: "#e7e6e4"
  },
  {
    name: "palette470",
    colors: [
      "#e64818",
      "#eab700",
      "#eecfca",
      "#2c6393"
    ],
    size: 4,
    background: "#e7e6e4"
  },
  {
    name: "palette471",
    colors: [
      "#e64818",
      "#2c6393",
      "#eab700",
      "#eecfca"
    ],
    size: 4,
    background: "#e7e6e4"
  },
  {
    name: "palette472",
    colors: [
      "#e64818",
      "#2c6393",
      "#eecfca",
      "#eab700"
    ],
    size: 4,
    background: "#e7e6e4"
  },
  {
    name: "palette473",
    colors: [
      "#e64818",
      "#eecfca",
      "#eab700",
      "#2c6393"
    ],
    size: 4,
    background: "#e7e6e4"
  },
  {
    name: "palette474",
    colors: [
      "#e64818",
      "#eecfca",
      "#2c6393",
      "#eab700"
    ],
    size: 4,
    background: "#e7e6e4"
  },
  {
    name: "palette475",
    colors: [
      "#2c6393",
      "#eab700",
      "#e64818",
      "#eecfca"
    ],
    size: 4,
    background: "#e7e6e4"
  },
  {
    name: "palette476",
    colors: [
      "#2c6393",
      "#eab700",
      "#eecfca",
      "#e64818"
    ],
    size: 4,
    background: "#e7e6e4"
  },
  {
    name: "palette477",
    colors: [
      "#2c6393",
      "#e64818",
      "#eab700",
      "#eecfca"
    ],
    size: 4,
    background: "#e7e6e4"
  },
  {
    name: "palette478",
    colors: [
      "#2c6393",
      "#e64818",
      "#eecfca",
      "#eab700"
    ],
    size: 4,
    background: "#e7e6e4"
  },
  {
    name: "palette479",
    colors: [
      "#2c6393",
      "#eecfca",
      "#eab700",
      "#e64818"
    ],
    size: 4,
    background: "#e7e6e4"
  },
  {
    name: "palette480",
    colors: [
      "#2c6393",
      "#eecfca",
      "#e64818",
      "#eab700"
    ],
    size: 4,
    background: "#e7e6e4"
  },
  {
    name: "palette483",
    colors: [
      "#eecfca",
      "#e64818",
      "#eab700",
      "#2c6393"
    ],
    size: 4,
    background: "#e7e6e4"
  },
  {
    name: "palette484",
    colors: [
      "#eecfca",
      "#e64818",
      "#2c6393",
      "#eab700"
    ],
    size: 4,
    background: "#e7e6e4"
  },
  {
    name: "palette485",
    colors: [
      "#eecfca",
      "#2c6393",
      "#eab700",
      "#e64818"
    ],
    size: 4,
    background: "#e7e6e4"
  },
  {
    name: "palette486",
    colors: [
      "#eecfca",
      "#2c6393",
      "#e64818",
      "#eab700"
    ],
    size: 4,
    background: "#e7e6e4"
  },
  {
    name: "palette489",
    colors: [
      "#0d4a4e",
      "#ead3a2",
      "#ff947b",
      "#5284ab"
    ],
    size: 4,
    background: "#f6f4ed"
  },
  {
    name: "palette490",
    colors: [
      "#0d4a4e",
      "#ead3a2",
      "#5284ab",
      "#ff947b"
    ],
    size: 4,
    background: "#f6f4ed"
  },
  {
    name: "palette491",
    colors: [
      "#0d4a4e",
      "#5284ab",
      "#ff947b",
      "#ead3a2"
    ],
    size: 4,
    background: "#f6f4ed"
  },
  {
    name: "palette492",
    colors: [
      "#0d4a4e",
      "#5284ab",
      "#ead3a2",
      "#ff947b"
    ],
    size: 4,
    background: "#f6f4ed"
  },
  {
    name: "palette493",
    colors: [
      "#ff947b",
      "#0d4a4e",
      "#ead3a2",
      "#5284ab"
    ],
    size: 4,
    background: "#f6f4ed"
  },
  {
    name: "palette498",
    colors: [
      "#ff947b",
      "#5284ab",
      "#ead3a2",
      "#0d4a4e"
    ],
    size: 4,
    background: "#f6f4ed"
  },
  {
    name: "palette499",
    colors: [
      "#ead3a2",
      "#0d4a4e",
      "#ff947b",
      "#5284ab"
    ],
    size: 4,
    background: "#f6f4ed"
  },
  {
    name: "palette500",
    colors: [
      "#ead3a2",
      "#0d4a4e",
      "#5284ab",
      "#ff947b"
    ],
    size: 4,
    background: "#f6f4ed"
  },
  {
    name: "palette503",
    colors: [
      "#ead3a2",
      "#5284ab",
      "#0d4a4e",
      "#ff947b"
    ],
    size: 4,
    background: "#f6f4ed"
  },
  {
    name: "palette504",
    colors: [
      "#ead3a2",
      "#5284ab",
      "#ff947b",
      "#0d4a4e"
    ],
    size: 4,
    background: "#f6f4ed"
  },
  {
    name: "palette505",
    colors: [
      "#5284ab",
      "#0d4a4e",
      "#ff947b",
      "#ead3a2"
    ],
    size: 4,
    background: "#f6f4ed"
  },
  {
    name: "palette506",
    colors: [
      "#5284ab",
      "#0d4a4e",
      "#ead3a2",
      "#ff947b"
    ],
    size: 4,
    background: "#f6f4ed"
  },
  {
    name: "palette507",
    colors: [
      "#5284ab",
      "#ff947b",
      "#0d4a4e",
      "#ead3a2"
    ],
    size: 4,
    background: "#f6f4ed"
  },
  {
    name: "palette508",
    colors: [
      "#5284ab",
      "#ff947b",
      "#ead3a2",
      "#0d4a4e"
    ],
    size: 4,
    background: "#f6f4ed"
  },
  {
    name: "palette509",
    colors: [
      "#5284ab",
      "#ead3a2",
      "#0d4a4e",
      "#ff947b"
    ],
    size: 4,
    background: "#f6f4ed"
  },
  {
    name: "palette510",
    colors: [
      "#5284ab",
      "#ead3a2",
      "#ff947b",
      "#0d4a4e"
    ],
    size: 4,
    background: "#f6f4ed"
  },
  {
    name: "palette511",
    colors: [
      "#eb5627",
      "#eebb20",
      "#4e9eb8",
      "#f7f5d0"
    ],
    size: 4,
    background: "#77c1c0"
  },
  {
    name: "palette512",
    colors: [
      "#eb5627",
      "#eebb20",
      "#f7f5d0",
      "#4e9eb8"
    ],
    size: 4,
    background: "#77c1c0"
  },
  {
    name: "palette513",
    colors: [
      "#eb5627",
      "#4e9eb8",
      "#eebb20",
      "#f7f5d0"
    ],
    size: 4,
    background: "#77c1c0"
  },
  {
    name: "palette514",
    colors: [
      "#eb5627",
      "#4e9eb8",
      "#f7f5d0",
      "#eebb20"
    ],
    size: 4,
    background: "#77c1c0"
  },
  {
    name: "palette515",
    colors: [
      "#eb5627",
      "#f7f5d0",
      "#eebb20",
      "#4e9eb8"
    ],
    size: 4,
    background: "#77c1c0"
  },
  {
    name: "palette516",
    colors: [
      "#eb5627",
      "#f7f5d0",
      "#4e9eb8",
      "#eebb20"
    ],
    size: 4,
    background: "#77c1c0"
  },
  {
    name: "palette517",
    colors: [
      "#eebb20",
      "#eb5627",
      "#4e9eb8",
      "#f7f5d0"
    ],
    size: 4,
    background: "#77c1c0"
  },
  {
    name: "palette518",
    colors: [
      "#eebb20",
      "#eb5627",
      "#f7f5d0",
      "#4e9eb8"
    ],
    size: 4,
    background: "#77c1c0"
  },
  {
    name: "palette519",
    colors: [
      "#eebb20",
      "#4e9eb8",
      "#eb5627",
      "#f7f5d0"
    ],
    size: 4,
    background: "#77c1c0"
  },
  {
    name: "palette520",
    colors: [
      "#eebb20",
      "#4e9eb8",
      "#f7f5d0",
      "#eb5627"
    ],
    size: 4,
    background: "#77c1c0"
  },
  {
    name: "palette527",
    colors: [
      "#4e9eb8",
      "#f7f5d0",
      "#eb5627",
      "#eebb20"
    ],
    size: 4,
    background: "#77c1c0"
  },
  {
    name: "palette528",
    colors: [
      "#4e9eb8",
      "#f7f5d0",
      "#eebb20",
      "#eb5627"
    ],
    size: 4,
    background: "#77c1c0"
  },
  {
    name: "palette529",
    colors: [
      "#f7f5d0",
      "#eb5627",
      "#eebb20",
      "#4e9eb8"
    ],
    size: 4,
    background: "#77c1c0"
  },
  {
    name: "palette530",
    colors: [
      "#f7f5d0",
      "#eb5627",
      "#4e9eb8",
      "#eebb20"
    ],
    size: 4,
    background: "#77c1c0"
  },
  {
    name: "palette533",
    colors: [
      "#f7f5d0",
      "#4e9eb8",
      "#eb5627",
      "#eebb20"
    ],
    size: 4,
    background: "#77c1c0"
  },
  {
    name: "palette534",
    colors: [
      "#f7f5d0",
      "#4e9eb8",
      "#eebb20",
      "#eb5627"
    ],
    size: 4,
    background: "#77c1c0"
  },
  {
    name: "palette537",
    colors: [
      "#abdfdf",
      "#58bdbc",
      "#fde500",
      "#eff0dd"
    ],
    size: 4,
    background: "#f76975"
  },
  {
    name: "palette538",
    colors: [
      "#abdfdf",
      "#58bdbc",
      "#eff0dd",
      "#fde500"
    ],
    size: 4,
    background: "#f76975"
  },
  {
    name: "palette547",
    colors: [
      "#58bdbc",
      "#abdfdf",
      "#fde500",
      "#eff0dd"
    ],
    size: 4,
    background: "#f76975"
  },
  {
    name: "palette548",
    colors: [
      "#58bdbc",
      "#abdfdf",
      "#eff0dd",
      "#fde500"
    ],
    size: 4,
    background: "#f76975"
  },
  {
    name: "palette551",
    colors: [
      "#58bdbc",
      "#eff0dd",
      "#abdfdf",
      "#fde500"
    ],
    size: 4,
    background: "#f76975"
  },
  {
    name: "palette552",
    colors: [
      "#58bdbc",
      "#eff0dd",
      "#fde500",
      "#abdfdf"
    ],
    size: 4,
    background: "#f76975"
  },
  {
    name: "palette557",
    colors: [
      "#eff0dd",
      "#58bdbc",
      "#abdfdf",
      "#fde500"
    ],
    size: 4,
    background: "#f76975"
  },
  {
    name: "palette558",
    colors: [
      "#eff0dd",
      "#58bdbc",
      "#fde500",
      "#abdfdf"
    ],
    size: 4,
    background: "#f76975"
  },
  {
    name: "palette559",
    colors: [
      "#db4549",
      "#d1e1e1",
      "#3e6a90",
      "#2e3853",
      "#a3c9d3"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette560",
    colors: [
      "#db4549",
      "#d1e1e1",
      "#3e6a90",
      "#a3c9d3",
      "#2e3853"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette561",
    colors: [
      "#db4549",
      "#d1e1e1",
      "#2e3853",
      "#3e6a90",
      "#a3c9d3"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette562",
    colors: [
      "#db4549",
      "#d1e1e1",
      "#2e3853",
      "#a3c9d3",
      "#3e6a90"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette563",
    colors: [
      "#db4549",
      "#d1e1e1",
      "#a3c9d3",
      "#3e6a90",
      "#2e3853"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette564",
    colors: [
      "#db4549",
      "#d1e1e1",
      "#a3c9d3",
      "#2e3853",
      "#3e6a90"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette565",
    colors: [
      "#db4549",
      "#3e6a90",
      "#d1e1e1",
      "#2e3853",
      "#a3c9d3"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette566",
    colors: [
      "#db4549",
      "#3e6a90",
      "#d1e1e1",
      "#a3c9d3",
      "#2e3853"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette567",
    colors: [
      "#db4549",
      "#3e6a90",
      "#2e3853",
      "#d1e1e1",
      "#a3c9d3"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette568",
    colors: [
      "#db4549",
      "#3e6a90",
      "#2e3853",
      "#a3c9d3",
      "#d1e1e1"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette569",
    colors: [
      "#db4549",
      "#3e6a90",
      "#a3c9d3",
      "#d1e1e1",
      "#2e3853"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette570",
    colors: [
      "#db4549",
      "#3e6a90",
      "#a3c9d3",
      "#2e3853",
      "#d1e1e1"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette571",
    colors: [
      "#db4549",
      "#2e3853",
      "#d1e1e1",
      "#3e6a90",
      "#a3c9d3"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette572",
    colors: [
      "#db4549",
      "#2e3853",
      "#d1e1e1",
      "#a3c9d3",
      "#3e6a90"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette573",
    colors: [
      "#db4549",
      "#2e3853",
      "#3e6a90",
      "#d1e1e1",
      "#a3c9d3"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette574",
    colors: [
      "#db4549",
      "#2e3853",
      "#3e6a90",
      "#a3c9d3",
      "#d1e1e1"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette575",
    colors: [
      "#db4549",
      "#2e3853",
      "#a3c9d3",
      "#d1e1e1",
      "#3e6a90"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette576",
    colors: [
      "#db4549",
      "#2e3853",
      "#a3c9d3",
      "#3e6a90",
      "#d1e1e1"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette583",
    colors: [
      "#d1e1e1",
      "#db4549",
      "#3e6a90",
      "#2e3853",
      "#a3c9d3"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette584",
    colors: [
      "#d1e1e1",
      "#db4549",
      "#3e6a90",
      "#a3c9d3",
      "#2e3853"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette585",
    colors: [
      "#d1e1e1",
      "#db4549",
      "#2e3853",
      "#3e6a90",
      "#a3c9d3"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette586",
    colors: [
      "#d1e1e1",
      "#db4549",
      "#2e3853",
      "#a3c9d3",
      "#3e6a90"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette587",
    colors: [
      "#d1e1e1",
      "#db4549",
      "#a3c9d3",
      "#3e6a90",
      "#2e3853"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette588",
    colors: [
      "#d1e1e1",
      "#db4549",
      "#a3c9d3",
      "#2e3853",
      "#3e6a90"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette589",
    colors: [
      "#d1e1e1",
      "#3e6a90",
      "#db4549",
      "#2e3853",
      "#a3c9d3"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette590",
    colors: [
      "#d1e1e1",
      "#3e6a90",
      "#db4549",
      "#a3c9d3",
      "#2e3853"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette591",
    colors: [
      "#d1e1e1",
      "#3e6a90",
      "#2e3853",
      "#db4549",
      "#a3c9d3"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette592",
    colors: [
      "#d1e1e1",
      "#3e6a90",
      "#2e3853",
      "#a3c9d3",
      "#db4549"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette593",
    colors: [
      "#d1e1e1",
      "#3e6a90",
      "#a3c9d3",
      "#db4549",
      "#2e3853"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette594",
    colors: [
      "#d1e1e1",
      "#3e6a90",
      "#a3c9d3",
      "#2e3853",
      "#db4549"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette595",
    colors: [
      "#d1e1e1",
      "#2e3853",
      "#db4549",
      "#3e6a90",
      "#a3c9d3"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette596",
    colors: [
      "#d1e1e1",
      "#2e3853",
      "#db4549",
      "#a3c9d3",
      "#3e6a90"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette597",
    colors: [
      "#d1e1e1",
      "#2e3853",
      "#3e6a90",
      "#db4549",
      "#a3c9d3"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette598",
    colors: [
      "#d1e1e1",
      "#2e3853",
      "#3e6a90",
      "#a3c9d3",
      "#db4549"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette599",
    colors: [
      "#d1e1e1",
      "#2e3853",
      "#a3c9d3",
      "#db4549",
      "#3e6a90"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette600",
    colors: [
      "#d1e1e1",
      "#2e3853",
      "#a3c9d3",
      "#3e6a90",
      "#db4549"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette607",
    colors: [
      "#3e6a90",
      "#db4549",
      "#d1e1e1",
      "#2e3853",
      "#a3c9d3"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette608",
    colors: [
      "#3e6a90",
      "#db4549",
      "#d1e1e1",
      "#a3c9d3",
      "#2e3853"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette609",
    colors: [
      "#3e6a90",
      "#db4549",
      "#2e3853",
      "#d1e1e1",
      "#a3c9d3"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette610",
    colors: [
      "#3e6a90",
      "#db4549",
      "#2e3853",
      "#a3c9d3",
      "#d1e1e1"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette611",
    colors: [
      "#3e6a90",
      "#db4549",
      "#a3c9d3",
      "#d1e1e1",
      "#2e3853"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette612",
    colors: [
      "#3e6a90",
      "#db4549",
      "#a3c9d3",
      "#2e3853",
      "#d1e1e1"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette613",
    colors: [
      "#3e6a90",
      "#d1e1e1",
      "#db4549",
      "#2e3853",
      "#a3c9d3"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette614",
    colors: [
      "#3e6a90",
      "#d1e1e1",
      "#db4549",
      "#a3c9d3",
      "#2e3853"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette615",
    colors: [
      "#3e6a90",
      "#d1e1e1",
      "#2e3853",
      "#db4549",
      "#a3c9d3"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette616",
    colors: [
      "#3e6a90",
      "#d1e1e1",
      "#2e3853",
      "#a3c9d3",
      "#db4549"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette617",
    colors: [
      "#3e6a90",
      "#d1e1e1",
      "#a3c9d3",
      "#db4549",
      "#2e3853"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette618",
    colors: [
      "#3e6a90",
      "#d1e1e1",
      "#a3c9d3",
      "#2e3853",
      "#db4549"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette619",
    colors: [
      "#3e6a90",
      "#2e3853",
      "#db4549",
      "#d1e1e1",
      "#a3c9d3"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette620",
    colors: [
      "#3e6a90",
      "#2e3853",
      "#db4549",
      "#a3c9d3",
      "#d1e1e1"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette621",
    colors: [
      "#3e6a90",
      "#2e3853",
      "#d1e1e1",
      "#db4549",
      "#a3c9d3"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette622",
    colors: [
      "#3e6a90",
      "#2e3853",
      "#d1e1e1",
      "#a3c9d3",
      "#db4549"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette623",
    colors: [
      "#3e6a90",
      "#2e3853",
      "#a3c9d3",
      "#db4549",
      "#d1e1e1"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette624",
    colors: [
      "#3e6a90",
      "#2e3853",
      "#a3c9d3",
      "#d1e1e1",
      "#db4549"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette625",
    colors: [
      "#3e6a90",
      "#a3c9d3",
      "#db4549",
      "#d1e1e1",
      "#2e3853"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette626",
    colors: [
      "#3e6a90",
      "#a3c9d3",
      "#db4549",
      "#2e3853",
      "#d1e1e1"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette627",
    colors: [
      "#3e6a90",
      "#a3c9d3",
      "#d1e1e1",
      "#db4549",
      "#2e3853"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette628",
    colors: [
      "#3e6a90",
      "#a3c9d3",
      "#d1e1e1",
      "#2e3853",
      "#db4549"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette629",
    colors: [
      "#3e6a90",
      "#a3c9d3",
      "#2e3853",
      "#db4549",
      "#d1e1e1"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette630",
    colors: [
      "#3e6a90",
      "#a3c9d3",
      "#2e3853",
      "#d1e1e1",
      "#db4549"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette631",
    colors: [
      "#2e3853",
      "#db4549",
      "#d1e1e1",
      "#3e6a90",
      "#a3c9d3"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette632",
    colors: [
      "#2e3853",
      "#db4549",
      "#d1e1e1",
      "#a3c9d3",
      "#3e6a90"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette633",
    colors: [
      "#2e3853",
      "#db4549",
      "#3e6a90",
      "#d1e1e1",
      "#a3c9d3"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette634",
    colors: [
      "#2e3853",
      "#db4549",
      "#3e6a90",
      "#a3c9d3",
      "#d1e1e1"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette635",
    colors: [
      "#2e3853",
      "#db4549",
      "#a3c9d3",
      "#d1e1e1",
      "#3e6a90"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette636",
    colors: [
      "#2e3853",
      "#db4549",
      "#a3c9d3",
      "#3e6a90",
      "#d1e1e1"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette637",
    colors: [
      "#2e3853",
      "#d1e1e1",
      "#db4549",
      "#3e6a90",
      "#a3c9d3"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette638",
    colors: [
      "#2e3853",
      "#d1e1e1",
      "#db4549",
      "#a3c9d3",
      "#3e6a90"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette639",
    colors: [
      "#2e3853",
      "#d1e1e1",
      "#3e6a90",
      "#db4549",
      "#a3c9d3"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette640",
    colors: [
      "#2e3853",
      "#d1e1e1",
      "#3e6a90",
      "#a3c9d3",
      "#db4549"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette641",
    colors: [
      "#2e3853",
      "#d1e1e1",
      "#a3c9d3",
      "#db4549",
      "#3e6a90"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette642",
    colors: [
      "#2e3853",
      "#d1e1e1",
      "#a3c9d3",
      "#3e6a90",
      "#db4549"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette643",
    colors: [
      "#2e3853",
      "#3e6a90",
      "#db4549",
      "#d1e1e1",
      "#a3c9d3"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette644",
    colors: [
      "#2e3853",
      "#3e6a90",
      "#db4549",
      "#a3c9d3",
      "#d1e1e1"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette645",
    colors: [
      "#2e3853",
      "#3e6a90",
      "#d1e1e1",
      "#db4549",
      "#a3c9d3"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette646",
    colors: [
      "#2e3853",
      "#3e6a90",
      "#d1e1e1",
      "#a3c9d3",
      "#db4549"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette647",
    colors: [
      "#2e3853",
      "#3e6a90",
      "#a3c9d3",
      "#db4549",
      "#d1e1e1"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette648",
    colors: [
      "#2e3853",
      "#3e6a90",
      "#a3c9d3",
      "#d1e1e1",
      "#db4549"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette649",
    colors: [
      "#2e3853",
      "#a3c9d3",
      "#db4549",
      "#d1e1e1",
      "#3e6a90"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette650",
    colors: [
      "#2e3853",
      "#a3c9d3",
      "#db4549",
      "#3e6a90",
      "#d1e1e1"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette651",
    colors: [
      "#2e3853",
      "#a3c9d3",
      "#d1e1e1",
      "#db4549",
      "#3e6a90"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette652",
    colors: [
      "#2e3853",
      "#a3c9d3",
      "#d1e1e1",
      "#3e6a90",
      "#db4549"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette653",
    colors: [
      "#2e3853",
      "#a3c9d3",
      "#3e6a90",
      "#db4549",
      "#d1e1e1"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette654",
    colors: [
      "#2e3853",
      "#a3c9d3",
      "#3e6a90",
      "#d1e1e1",
      "#db4549"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette655",
    colors: [
      "#a3c9d3",
      "#db4549",
      "#d1e1e1",
      "#3e6a90",
      "#2e3853"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette656",
    colors: [
      "#a3c9d3",
      "#db4549",
      "#d1e1e1",
      "#2e3853",
      "#3e6a90"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette657",
    colors: [
      "#a3c9d3",
      "#db4549",
      "#3e6a90",
      "#d1e1e1",
      "#2e3853"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette658",
    colors: [
      "#a3c9d3",
      "#db4549",
      "#3e6a90",
      "#2e3853",
      "#d1e1e1"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette659",
    colors: [
      "#a3c9d3",
      "#db4549",
      "#2e3853",
      "#d1e1e1",
      "#3e6a90"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette660",
    colors: [
      "#a3c9d3",
      "#db4549",
      "#2e3853",
      "#3e6a90",
      "#d1e1e1"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette661",
    colors: [
      "#a3c9d3",
      "#d1e1e1",
      "#db4549",
      "#3e6a90",
      "#2e3853"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette662",
    colors: [
      "#a3c9d3",
      "#d1e1e1",
      "#db4549",
      "#2e3853",
      "#3e6a90"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette663",
    colors: [
      "#a3c9d3",
      "#d1e1e1",
      "#3e6a90",
      "#db4549",
      "#2e3853"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette664",
    colors: [
      "#a3c9d3",
      "#d1e1e1",
      "#3e6a90",
      "#2e3853",
      "#db4549"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette665",
    colors: [
      "#a3c9d3",
      "#d1e1e1",
      "#2e3853",
      "#db4549",
      "#3e6a90"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette666",
    colors: [
      "#a3c9d3",
      "#d1e1e1",
      "#2e3853",
      "#3e6a90",
      "#db4549"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette667",
    colors: [
      "#a3c9d3",
      "#3e6a90",
      "#db4549",
      "#d1e1e1",
      "#2e3853"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette668",
    colors: [
      "#a3c9d3",
      "#3e6a90",
      "#db4549",
      "#2e3853",
      "#d1e1e1"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette669",
    colors: [
      "#a3c9d3",
      "#3e6a90",
      "#d1e1e1",
      "#db4549",
      "#2e3853"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette670",
    colors: [
      "#a3c9d3",
      "#3e6a90",
      "#d1e1e1",
      "#2e3853",
      "#db4549"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette671",
    colors: [
      "#a3c9d3",
      "#3e6a90",
      "#2e3853",
      "#db4549",
      "#d1e1e1"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette672",
    colors: [
      "#a3c9d3",
      "#3e6a90",
      "#2e3853",
      "#d1e1e1",
      "#db4549"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette673",
    colors: [
      "#a3c9d3",
      "#2e3853",
      "#db4549",
      "#d1e1e1",
      "#3e6a90"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette674",
    colors: [
      "#a3c9d3",
      "#2e3853",
      "#db4549",
      "#3e6a90",
      "#d1e1e1"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette675",
    colors: [
      "#a3c9d3",
      "#2e3853",
      "#d1e1e1",
      "#db4549",
      "#3e6a90"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette676",
    colors: [
      "#a3c9d3",
      "#2e3853",
      "#d1e1e1",
      "#3e6a90",
      "#db4549"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette677",
    colors: [
      "#a3c9d3",
      "#2e3853",
      "#3e6a90",
      "#db4549",
      "#d1e1e1"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette678",
    colors: [
      "#a3c9d3",
      "#2e3853",
      "#3e6a90",
      "#d1e1e1",
      "#db4549"
    ],
    size: 5,
    background: "#ffffff"
  },
  {
    name: "palette683",
    colors: [
      "#5c5f46",
      "#66aeaa",
      "#ff7044",
      "#ffce39"
    ],
    size: 4,
    background: "#e9ecde"
  },
  {
    name: "palette684",
    colors: [
      "#5c5f46",
      "#66aeaa",
      "#ffce39",
      "#ff7044"
    ],
    size: 4,
    background: "#e9ecde"
  },
  {
    name: "palette687",
    colors: [
      "#ff7044",
      "#ffce39",
      "#5c5f46",
      "#66aeaa"
    ],
    size: 4,
    background: "#e9ecde"
  },
  {
    name: "palette688",
    colors: [
      "#ff7044",
      "#ffce39",
      "#66aeaa",
      "#5c5f46"
    ],
    size: 4,
    background: "#e9ecde"
  },
  {
    name: "palette691",
    colors: [
      "#ffce39",
      "#5c5f46",
      "#ff7044",
      "#66aeaa"
    ],
    size: 4,
    background: "#e9ecde"
  },
  {
    name: "palette692",
    colors: [
      "#ffce39",
      "#5c5f46",
      "#66aeaa",
      "#ff7044"
    ],
    size: 4,
    background: "#e9ecde"
  },
  {
    name: "palette693",
    colors: [
      "#ffce39",
      "#ff7044",
      "#5c5f46",
      "#66aeaa"
    ],
    size: 4,
    background: "#e9ecde"
  },
  {
    name: "palette694",
    colors: [
      "#ffce39",
      "#ff7044",
      "#66aeaa",
      "#5c5f46"
    ],
    size: 4,
    background: "#e9ecde"
  },
  {
    name: "palette695",
    colors: [
      "#ffce39",
      "#66aeaa",
      "#5c5f46",
      "#ff7044"
    ],
    size: 4,
    background: "#e9ecde"
  },
  {
    name: "palette696",
    colors: [
      "#ffce39",
      "#66aeaa",
      "#ff7044",
      "#5c5f46"
    ],
    size: 4,
    background: "#e9ecde"
  },
  {
    name: "palette697",
    colors: [
      "#66aeaa",
      "#5c5f46",
      "#ff7044",
      "#ffce39"
    ],
    size: 4,
    background: "#e9ecde"
  },
  {
    name: "palette698",
    colors: [
      "#66aeaa",
      "#5c5f46",
      "#ffce39",
      "#ff7044"
    ],
    size: 4,
    background: "#e9ecde"
  },
  {
    name: "palette699",
    colors: [
      "#66aeaa",
      "#ff7044",
      "#5c5f46",
      "#ffce39"
    ],
    size: 4,
    background: "#e9ecde"
  },
  {
    name: "palette700",
    colors: [
      "#66aeaa",
      "#ff7044",
      "#ffce39",
      "#5c5f46"
    ],
    size: 4,
    background: "#e9ecde"
  },
  {
    name: "palette701",
    colors: [
      "#66aeaa",
      "#ffce39",
      "#5c5f46",
      "#ff7044"
    ],
    size: 4,
    background: "#e9ecde"
  },
  {
    name: "palette702",
    colors: [
      "#66aeaa",
      "#ffce39",
      "#ff7044",
      "#5c5f46"
    ],
    size: 4,
    background: "#e9ecde"
  },
  {
    name: "palette703",
    colors: [
      "#e3937b",
      "#d93f1d",
      "#090d15",
      "#e6cca7"
    ],
    size: 4,
    background: "#558947"
  },
  {
    name: "palette704",
    colors: [
      "#e3937b",
      "#d93f1d",
      "#e6cca7",
      "#090d15"
    ],
    size: 4,
    background: "#558947"
  },
  {
    name: "palette705",
    colors: [
      "#e3937b",
      "#090d15",
      "#d93f1d",
      "#e6cca7"
    ],
    size: 4,
    background: "#558947"
  },
  {
    name: "palette706",
    colors: [
      "#e3937b",
      "#090d15",
      "#e6cca7",
      "#d93f1d"
    ],
    size: 4,
    background: "#558947"
  },
  {
    name: "palette709",
    colors: [
      "#d93f1d",
      "#e3937b",
      "#090d15",
      "#e6cca7"
    ],
    size: 4,
    background: "#558947"
  },
  {
    name: "palette710",
    colors: [
      "#d93f1d",
      "#e3937b",
      "#e6cca7",
      "#090d15"
    ],
    size: 4,
    background: "#558947"
  },
  {
    name: "palette711",
    colors: [
      "#d93f1d",
      "#090d15",
      "#e3937b",
      "#e6cca7"
    ],
    size: 4,
    background: "#558947"
  },
  {
    name: "palette712",
    colors: [
      "#d93f1d",
      "#090d15",
      "#e6cca7",
      "#e3937b"
    ],
    size: 4,
    background: "#558947"
  },
  {
    name: "palette713",
    colors: [
      "#d93f1d",
      "#e6cca7",
      "#e3937b",
      "#090d15"
    ],
    size: 4,
    background: "#558947"
  },
  {
    name: "palette714",
    colors: [
      "#d93f1d",
      "#e6cca7",
      "#090d15",
      "#e3937b"
    ],
    size: 4,
    background: "#558947"
  },
  {
    name: "palette715",
    colors: [
      "#090d15",
      "#e3937b",
      "#d93f1d",
      "#e6cca7"
    ],
    size: 4,
    background: "#558947"
  },
  {
    name: "palette716",
    colors: [
      "#090d15",
      "#e3937b",
      "#e6cca7",
      "#d93f1d"
    ],
    size: 4,
    background: "#558947"
  },
  {
    name: "palette717",
    colors: [
      "#090d15",
      "#d93f1d",
      "#e3937b",
      "#e6cca7"
    ],
    size: 4,
    background: "#558947"
  },
  {
    name: "palette718",
    colors: [
      "#090d15",
      "#d93f1d",
      "#e6cca7",
      "#e3937b"
    ],
    size: 4,
    background: "#558947"
  },
  {
    name: "palette719",
    colors: [
      "#090d15",
      "#e6cca7",
      "#e3937b",
      "#d93f1d"
    ],
    size: 4,
    background: "#558947"
  },
  {
    name: "palette720",
    colors: [
      "#090d15",
      "#e6cca7",
      "#d93f1d",
      "#e3937b"
    ],
    size: 4,
    background: "#558947"
  },
  {
    name: "palette721",
    colors: [
      "#e6cca7",
      "#e3937b",
      "#d93f1d",
      "#090d15"
    ],
    size: 4,
    background: "#558947"
  },
  {
    name: "palette722",
    colors: [
      "#e6cca7",
      "#e3937b",
      "#090d15",
      "#d93f1d"
    ],
    size: 4,
    background: "#558947"
  },
  {
    name: "palette723",
    colors: [
      "#e6cca7",
      "#d93f1d",
      "#e3937b",
      "#090d15"
    ],
    size: 4,
    background: "#558947"
  },
  {
    name: "palette724",
    colors: [
      "#e6cca7",
      "#d93f1d",
      "#090d15",
      "#e3937b"
    ],
    size: 4,
    background: "#558947"
  },
  {
    name: "palette725",
    colors: [
      "#e6cca7",
      "#090d15",
      "#e3937b",
      "#d93f1d"
    ],
    size: 4,
    background: "#558947"
  },
  {
    name: "palette726",
    colors: [
      "#e6cca7",
      "#090d15",
      "#d93f1d",
      "#e3937b"
    ],
    size: 4,
    background: "#558947"
  },
  {
    name: "palette729",
    colors: [
      "#EEEBDD",
      "#630000",
      "#D8B6A4",
      "#000000"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette730",
    colors: [
      "#EEEBDD",
      "#630000",
      "#000000",
      "#D8B6A4"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette731",
    colors: [
      "#EEEBDD",
      "#000000",
      "#D8B6A4",
      "#630000"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette732",
    colors: [
      "#EEEBDD",
      "#000000",
      "#630000",
      "#D8B6A4"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette735",
    colors: [
      "#D8B6A4",
      "#630000",
      "#EEEBDD",
      "#000000"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette736",
    colors: [
      "#D8B6A4",
      "#630000",
      "#000000",
      "#EEEBDD"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette737",
    colors: [
      "#D8B6A4",
      "#000000",
      "#EEEBDD",
      "#630000"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette738",
    colors: [
      "#D8B6A4",
      "#000000",
      "#630000",
      "#EEEBDD"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette739",
    colors: [
      "#630000",
      "#EEEBDD",
      "#D8B6A4",
      "#000000"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette740",
    colors: [
      "#630000",
      "#EEEBDD",
      "#000000",
      "#D8B6A4"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette741",
    colors: [
      "#630000",
      "#D8B6A4",
      "#EEEBDD",
      "#000000"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette742",
    colors: [
      "#630000",
      "#D8B6A4",
      "#000000",
      "#EEEBDD"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette743",
    colors: [
      "#630000",
      "#000000",
      "#EEEBDD",
      "#D8B6A4"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette744",
    colors: [
      "#630000",
      "#000000",
      "#D8B6A4",
      "#EEEBDD"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette745",
    colors: [
      "#000000",
      "#EEEBDD",
      "#D8B6A4",
      "#630000"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette746",
    colors: [
      "#000000",
      "#EEEBDD",
      "#630000",
      "#D8B6A4"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette747",
    colors: [
      "#000000",
      "#D8B6A4",
      "#EEEBDD",
      "#630000"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette748",
    colors: [
      "#000000",
      "#D8B6A4",
      "#630000",
      "#EEEBDD"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette749",
    colors: [
      "#000000",
      "#630000",
      "#EEEBDD",
      "#D8B6A4"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette750",
    colors: [
      "#000000",
      "#630000",
      "#D8B6A4",
      "#EEEBDD"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette753",
    colors: [
      "#F90716",
      "#FFCA03",
      "#FF5403",
      "#FFF323"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette754",
    colors: [
      "#F90716",
      "#FFCA03",
      "#FFF323",
      "#FF5403"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette755",
    colors: [
      "#F90716",
      "#FFF323",
      "#FF5403",
      "#FFCA03"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette756",
    colors: [
      "#F90716",
      "#FFF323",
      "#FFCA03",
      "#FF5403"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette759",
    colors: [
      "#FF5403",
      "#FFCA03",
      "#F90716",
      "#FFF323"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette760",
    colors: [
      "#FF5403",
      "#FFCA03",
      "#FFF323",
      "#F90716"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette761",
    colors: [
      "#FF5403",
      "#FFF323",
      "#F90716",
      "#FFCA03"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette762",
    colors: [
      "#FF5403",
      "#FFF323",
      "#FFCA03",
      "#F90716"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette763",
    colors: [
      "#FFCA03",
      "#F90716",
      "#FF5403",
      "#FFF323"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette764",
    colors: [
      "#FFCA03",
      "#F90716",
      "#FFF323",
      "#FF5403"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette765",
    colors: [
      "#FFCA03",
      "#FF5403",
      "#F90716",
      "#FFF323"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette766",
    colors: [
      "#FFCA03",
      "#FF5403",
      "#FFF323",
      "#F90716"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette769",
    colors: [
      "#FFF323",
      "#F90716",
      "#FF5403",
      "#FFCA03"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette770",
    colors: [
      "#FFF323",
      "#F90716",
      "#FFCA03",
      "#FF5403"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette771",
    colors: [
      "#FFF323",
      "#FF5403",
      "#F90716",
      "#FFCA03"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette772",
    colors: [
      "#FFF323",
      "#FF5403",
      "#FFCA03",
      "#F90716"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette775",
    colors: [
      "#222831",
      "#393E46",
      "#00ADB5",
      "#EEEEEE"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette776",
    colors: [
      "#222831",
      "#393E46",
      "#EEEEEE",
      "#00ADB5"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette777",
    colors: [
      "#222831",
      "#00ADB5",
      "#393E46",
      "#EEEEEE"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette778",
    colors: [
      "#222831",
      "#00ADB5",
      "#EEEEEE",
      "#393E46"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette779",
    colors: [
      "#222831",
      "#EEEEEE",
      "#393E46",
      "#00ADB5"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette780",
    colors: [
      "#222831",
      "#EEEEEE",
      "#00ADB5",
      "#393E46"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette781",
    colors: [
      "#393E46",
      "#222831",
      "#00ADB5",
      "#EEEEEE"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette782",
    colors: [
      "#393E46",
      "#222831",
      "#EEEEEE",
      "#00ADB5"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette783",
    colors: [
      "#393E46",
      "#00ADB5",
      "#222831",
      "#EEEEEE"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette784",
    colors: [
      "#393E46",
      "#00ADB5",
      "#EEEEEE",
      "#222831"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette785",
    colors: [
      "#393E46",
      "#EEEEEE",
      "#222831",
      "#00ADB5"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette786",
    colors: [
      "#393E46",
      "#EEEEEE",
      "#00ADB5",
      "#222831"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette787",
    colors: [
      "#00ADB5",
      "#222831",
      "#393E46",
      "#EEEEEE"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette788",
    colors: [
      "#00ADB5",
      "#222831",
      "#EEEEEE",
      "#393E46"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette789",
    colors: [
      "#00ADB5",
      "#393E46",
      "#222831",
      "#EEEEEE"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette790",
    colors: [
      "#00ADB5",
      "#393E46",
      "#EEEEEE",
      "#222831"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette791",
    colors: [
      "#00ADB5",
      "#EEEEEE",
      "#222831",
      "#393E46"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette792",
    colors: [
      "#00ADB5",
      "#EEEEEE",
      "#393E46",
      "#222831"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette793",
    colors: [
      "#EEEEEE",
      "#222831",
      "#393E46",
      "#00ADB5"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette794",
    colors: [
      "#EEEEEE",
      "#222831",
      "#00ADB5",
      "#393E46"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette795",
    colors: [
      "#EEEEEE",
      "#393E46",
      "#222831",
      "#00ADB5"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette796",
    colors: [
      "#EEEEEE",
      "#393E46",
      "#00ADB5",
      "#222831"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette797",
    colors: [
      "#EEEEEE",
      "#00ADB5",
      "#222831",
      "#393E46"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette798",
    colors: [
      "#EEEEEE",
      "#00ADB5",
      "#393E46",
      "#222831"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette847",
    colors: [
      "#FCF8E8",
      "#D4E2D4",
      "#ECB390",
      "#DF7861"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette851",
    colors: [
      "#FCF8E8",
      "#DF7861",
      "#D4E2D4",
      "#ECB390"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette852",
    colors: [
      "#FCF8E8",
      "#DF7861",
      "#ECB390",
      "#D4E2D4"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette857",
    colors: [
      "#D4E2D4",
      "#DF7861",
      "#FCF8E8",
      "#ECB390"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette858",
    colors: [
      "#D4E2D4",
      "#DF7861",
      "#ECB390",
      "#FCF8E8"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette863",
    colors: [
      "#ECB390",
      "#DF7861",
      "#FCF8E8",
      "#D4E2D4"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette864",
    colors: [
      "#ECB390",
      "#DF7861",
      "#D4E2D4",
      "#FCF8E8"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette865",
    colors: [
      "#DF7861",
      "#FCF8E8",
      "#D4E2D4",
      "#ECB390"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette866",
    colors: [
      "#DF7861",
      "#FCF8E8",
      "#ECB390",
      "#D4E2D4"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette867",
    colors: [
      "#DF7861",
      "#D4E2D4",
      "#FCF8E8",
      "#ECB390"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette868",
    colors: [
      "#DF7861",
      "#D4E2D4",
      "#ECB390",
      "#FCF8E8"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette869",
    colors: [
      "#DF7861",
      "#ECB390",
      "#FCF8E8",
      "#D4E2D4"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette870",
    colors: [
      "#DF7861",
      "#ECB390",
      "#D4E2D4",
      "#FCF8E8"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette873",
    colors: [
      "#303841",
      "#D72323",
      "#3A4750",
      "#EEEEEE"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette874",
    colors: [
      "#303841",
      "#D72323",
      "#EEEEEE",
      "#3A4750"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette875",
    colors: [
      "#303841",
      "#EEEEEE",
      "#3A4750",
      "#D72323"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette876",
    colors: [
      "#303841",
      "#EEEEEE",
      "#D72323",
      "#3A4750"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette879",
    colors: [
      "#3A4750",
      "#D72323",
      "#303841",
      "#EEEEEE"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette880",
    colors: [
      "#3A4750",
      "#D72323",
      "#EEEEEE",
      "#303841"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette881",
    colors: [
      "#3A4750",
      "#EEEEEE",
      "#303841",
      "#D72323"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette882",
    colors: [
      "#3A4750",
      "#EEEEEE",
      "#D72323",
      "#303841"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette883",
    colors: [
      "#D72323",
      "#303841",
      "#3A4750",
      "#EEEEEE"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette884",
    colors: [
      "#D72323",
      "#303841",
      "#EEEEEE",
      "#3A4750"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette885",
    colors: [
      "#D72323",
      "#3A4750",
      "#303841",
      "#EEEEEE"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette886",
    colors: [
      "#D72323",
      "#3A4750",
      "#EEEEEE",
      "#303841"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette887",
    colors: [
      "#D72323",
      "#EEEEEE",
      "#303841",
      "#3A4750"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette888",
    colors: [
      "#D72323",
      "#EEEEEE",
      "#3A4750",
      "#303841"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette889",
    colors: [
      "#EEEEEE",
      "#303841",
      "#3A4750",
      "#D72323"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette890",
    colors: [
      "#EEEEEE",
      "#303841",
      "#D72323",
      "#3A4750"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette891",
    colors: [
      "#EEEEEE",
      "#3A4750",
      "#303841",
      "#D72323"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette892",
    colors: [
      "#EEEEEE",
      "#3A4750",
      "#D72323",
      "#303841"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette893",
    colors: [
      "#EEEEEE",
      "#D72323",
      "#303841",
      "#3A4750"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette894",
    colors: [
      "#EEEEEE",
      "#D72323",
      "#3A4750",
      "#303841"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette897",
    colors: [
      "#FF4646",
      "#FFB396",
      "#FF8585",
      "#FFF5C0"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette898",
    colors: [
      "#FF4646",
      "#FFB396",
      "#FFF5C0",
      "#FF8585"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette899",
    colors: [
      "#FF4646",
      "#FFF5C0",
      "#FF8585",
      "#FFB396"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette900",
    colors: [
      "#FF4646",
      "#FFF5C0",
      "#FFB396",
      "#FF8585"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette907",
    colors: [
      "#FFB396",
      "#FF4646",
      "#FF8585",
      "#FFF5C0"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette908",
    colors: [
      "#FFB396",
      "#FF4646",
      "#FFF5C0",
      "#FF8585"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette913",
    colors: [
      "#FFF5C0",
      "#FF4646",
      "#FF8585",
      "#FFB396"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette914",
    colors: [
      "#FFF5C0",
      "#FF4646",
      "#FFB396",
      "#FF8585"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette915",
    colors: [
      "#FFF5C0",
      "#FF8585",
      "#FF4646",
      "#FFB396"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette916",
    colors: [
      "#FFF5C0",
      "#FF8585",
      "#FFB396",
      "#FF4646"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette921",
    colors: [
      "#8C0000",
      "#FA1E0E",
      "#BD2000",
      "#FFBE0F"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette922",
    colors: [
      "#8C0000",
      "#FA1E0E",
      "#FFBE0F",
      "#BD2000"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette923",
    colors: [
      "#8C0000",
      "#FFBE0F",
      "#BD2000",
      "#FA1E0E"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette924",
    colors: [
      "#8C0000",
      "#FFBE0F",
      "#FA1E0E",
      "#BD2000"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette929",
    colors: [
      "#BD2000",
      "#FFBE0F",
      "#8C0000",
      "#FA1E0E"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette930",
    colors: [
      "#BD2000",
      "#FFBE0F",
      "#FA1E0E",
      "#8C0000"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette931",
    colors: [
      "#FA1E0E",
      "#8C0000",
      "#BD2000",
      "#FFBE0F"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette932",
    colors: [
      "#FA1E0E",
      "#8C0000",
      "#FFBE0F",
      "#BD2000"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette935",
    colors: [
      "#FA1E0E",
      "#FFBE0F",
      "#8C0000",
      "#BD2000"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette936",
    colors: [
      "#FA1E0E",
      "#FFBE0F",
      "#BD2000",
      "#8C0000"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette937",
    colors: [
      "#FFBE0F",
      "#8C0000",
      "#BD2000",
      "#FA1E0E"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette938",
    colors: [
      "#FFBE0F",
      "#8C0000",
      "#FA1E0E",
      "#BD2000"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette939",
    colors: [
      "#FFBE0F",
      "#BD2000",
      "#8C0000",
      "#FA1E0E"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette940",
    colors: [
      "#FFBE0F",
      "#BD2000",
      "#FA1E0E",
      "#8C0000"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette941",
    colors: [
      "#FFBE0F",
      "#FA1E0E",
      "#8C0000",
      "#BD2000"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette942",
    colors: [
      "#FFBE0F",
      "#FA1E0E",
      "#BD2000",
      "#8C0000"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette943",
    colors: [
      "#A2D2FF",
      "#FEF9EF",
      "#FF865E",
      "#FEE440"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette944",
    colors: [
      "#A2D2FF",
      "#FEF9EF",
      "#FEE440",
      "#FF865E"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette945",
    colors: [
      "#A2D2FF",
      "#FF865E",
      "#FEF9EF",
      "#FEE440"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette946",
    colors: [
      "#A2D2FF",
      "#FF865E",
      "#FEE440",
      "#FEF9EF"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette949",
    colors: [
      "#FEF9EF",
      "#A2D2FF",
      "#FF865E",
      "#FEE440"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette950",
    colors: [
      "#FEF9EF",
      "#A2D2FF",
      "#FEE440",
      "#FF865E"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette951",
    colors: [
      "#FEF9EF",
      "#FF865E",
      "#A2D2FF",
      "#FEE440"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette952",
    colors: [
      "#FEF9EF",
      "#FF865E",
      "#FEE440",
      "#A2D2FF"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette955",
    colors: [
      "#FF865E",
      "#A2D2FF",
      "#FEF9EF",
      "#FEE440"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette956",
    colors: [
      "#FF865E",
      "#A2D2FF",
      "#FEE440",
      "#FEF9EF"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette957",
    colors: [
      "#FF865E",
      "#FEF9EF",
      "#A2D2FF",
      "#FEE440"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette958",
    colors: [
      "#FF865E",
      "#FEF9EF",
      "#FEE440",
      "#A2D2FF"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette967",
    colors: [
      "#F55C47",
      "#9FE6A0",
      "#4AA96C",
      "#564A4A"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette968",
    colors: [
      "#F55C47",
      "#9FE6A0",
      "#564A4A",
      "#4AA96C"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette971",
    colors: [
      "#F55C47",
      "#564A4A",
      "#9FE6A0",
      "#4AA96C"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette972",
    colors: [
      "#F55C47",
      "#564A4A",
      "#4AA96C",
      "#9FE6A0"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette973",
    colors: [
      "#9FE6A0",
      "#F55C47",
      "#4AA96C",
      "#564A4A"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette974",
    colors: [
      "#9FE6A0",
      "#F55C47",
      "#564A4A",
      "#4AA96C"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette975",
    colors: [
      "#9FE6A0",
      "#4AA96C",
      "#F55C47",
      "#564A4A"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette976",
    colors: [
      "#9FE6A0",
      "#4AA96C",
      "#564A4A",
      "#F55C47"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette977",
    colors: [
      "#9FE6A0",
      "#564A4A",
      "#F55C47",
      "#4AA96C"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette978",
    colors: [
      "#9FE6A0",
      "#564A4A",
      "#4AA96C",
      "#F55C47"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette981",
    colors: [
      "#4AA96C",
      "#9FE6A0",
      "#F55C47",
      "#564A4A"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette982",
    colors: [
      "#4AA96C",
      "#9FE6A0",
      "#564A4A",
      "#F55C47"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette983",
    colors: [
      "#4AA96C",
      "#564A4A",
      "#F55C47",
      "#9FE6A0"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette984",
    colors: [
      "#4AA96C",
      "#564A4A",
      "#9FE6A0",
      "#F55C47"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette985",
    colors: [
      "#564A4A",
      "#F55C47",
      "#9FE6A0",
      "#4AA96C"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette986",
    colors: [
      "#564A4A",
      "#F55C47",
      "#4AA96C",
      "#9FE6A0"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette987",
    colors: [
      "#564A4A",
      "#9FE6A0",
      "#F55C47",
      "#4AA96C"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette988",
    colors: [
      "#564A4A",
      "#9FE6A0",
      "#4AA96C",
      "#F55C47"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette989",
    colors: [
      "#564A4A",
      "#4AA96C",
      "#F55C47",
      "#9FE6A0"
    ],
    size: 4,
    background: "#ffffff"
  },
  {
    name: "palette990",
    colors: [
      "#564A4A",
      "#4AA96C",
      "#9FE6A0",
      "#F55C47"
    ],
    size: 4,
    background: "#ffffff"
  }
]

const mappedColors = Object.assign({}, ...palettes.map((x) => ({[x.name]: x.background})));


const SoloEpochSketch = ({ data }) => {
  const palette = data.epochsJson.colors;
  const bgColor = mappedColors[palette];

  return (
    <WrapperDiv style={{ background: bgColor }}>
        <P5Component data={data.epochsJson} />
    </WrapperDiv>
  )
}

export const query = graphql`
  query EpocSketchQuery($id: String) {
    epochsJson(id: {eq: $id}) {
      id
      name
      cluster_type
      colors
      neighborhood_assignment
      node_styles
      scheduled_rotation
      seed
      shadow_blur_level
      shape
      show_tiles_at_depth
      turbine_depth_maximum
      turbine_depth_minimum
    }
  }
`

export default SoloEpochSketch
